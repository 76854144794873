import Vue from "vue";
import Vuetify from "vuetify/lib";
import i18n from "./i18n";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    //disable: true,
    light: true,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#ff9800",
        accent: "#D07C00",
        secondary: "#583D3E"
      }
    }
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  icons: {
    values: {
      star: "icon-star",
      search: "icon-search",
      heart: "icon-heart",
      heartFull: "icon-heartfull",
      menu: "icon-burgermenu",
      profile: "icon-profile",
      cart: "icon-cart",
      minus: "icon-minus",
      plus: "icon-plus",
      edit: "icon-edit",
      cardArrow: "icon-card-arrow",
      print: "icon-print",
      delete: "icon-delete",
      clock: "icon-clock",
      help: "icon-help",
      orders: "icon-orders",
      home: "icon-home",
      replace: "icon-replace"
    }
  }
});
