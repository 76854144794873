<template>
  <div v-if="remaining > 0" class="subtitle-2 minimum-purchase">
    {{ $t("checkout.minimumPurchase", [$n(remaining, "currency")]) }}
  </div>
</template>
<style scoped lang="scss">
.minimum-purchase {
  color: red;
}
</style>
<script>
import CartService from "~/service/cartService";
import deliveryReactive from "~/mixins/deliveryReactive";

import { mapState } from "vuex";

export default {
  name: "PurchaseMinimum",
  mixins: [deliveryReactive],
  data() {
    return {
      minimumPurchase: null
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    remaining() {
      return this.minimumPurchase - this.cart.totalPrice;
    }
  },
  methods: {
    async reload() {
      const purchaseRestriction = await CartService.getPurchaseRestriction();
      this.minimumPurchase = purchaseRestriction.minimumPurchase;
    }
  },
  async mounted() {
    this.reload();
  }
};
</script>
