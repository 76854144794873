<template>
  <div
    class="d-flex not-available-product"
    :class="{ 'is-checkout': isCheckout }"
  >
    <div v-if="showTooltip && !isCheckout">
      <v-tooltip bottom>
        <template v-if="hour" v-slot:activator="{ on, attrs }">
          <div
            class="hour-lock available-range"
            dark
            v-bind="attrs"
            v-on="on"
            :hour="hour"
            @click.prevent=""
            @mousedown.stop
          >
            <v-icon x-small>$clock</v-icon>
            <!-- <span v-html="hour.shortText"></span> -->
          </div>
        </template>
        <template v-else v-slot:activator="{ on, attrs }">
          <div class="hour-lock" dark v-bind="attrs" v-on="on" :hour="hour">
            <v-icon x-small>$clock</v-icon>
            <!-- <span v-html="hour.shortText"></span> -->
          </div>
        </template>
        <div class="tooltip-description">
          <span v-html="hourLocks"></span>
        </div>
      </v-tooltip>
    </div>
    <div v-else class="hour-lock d-flex">
      <v-icon x-small>$clock</v-icon>
      <div class="not-available-text">
        <p class="not-available-description" v-if="hour" v-html="hourLocks"></p>
        <p v-else class="not-available-description" v-html="hourLocks"></p>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.cart-item {
  .hour-lock i {
    font-size: 18px !important;
  }
  .not-available-description {
    font-size: 11px;
  }
}
.hour-lock {
  font-weight: 800;
  font-size: 11px;
  text-transform: uppercase;
  // background-color: $lock-cutoff-color;
  // color: $text-color;
  // padding: 4px;
  // margin-right: 5px;
  // border-radius: 4px;
  // text-decoration: line-through;
  i {
    color: $text-color !important;
    font-size: 28px !important;
  }
}
.tooltip-description {
  max-width: 350px;
}
.hour-lock.available-range {
  text-decoration: none;
  // background-color: $lock-cutoff-available-range-color;
}
.not-available-text {
  display: flex;
  align-items: center;
}
.not-available-description {
  font-size: 13px;
  line-height: 1.1;
  text-transform: none;
  font-weight: 200;
  margin: 0px 6px 0px 6px;
  padding: 0px;
}
</style>
<script>
export default {
  name: "ProductAxBCutoff",
  props: ["hour", "showTooltip", "isCheckout"],
  computed: {
    hourLocks() {
      let result = "";
      result = this.$t("axbCutoff.availableRange", [this.hour]);
      return result;
    }
  }
};
</script>
