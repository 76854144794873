<template>
  <div
    class="d-flex not-available-product"
    :class="{ 'is-checkout': isCheckout }"
  >
    <v-tooltip v-if="showTooltip && !isCheckout" bottom>
      <template v-slot:activator="{ on, attrs }">
        <div
          class="day-lock"
          dark
          v-bind="attrs"
          v-on="on"
          @click.prevent=""
          @mousedown.stop
        >
          <v-icon x-small>$clock</v-icon>
          <!-- <span v-html="day.shortText"></span> -->
        </div>
      </template>
      <div class="tooltip-description">
        <span v-html="dayLocks.longText"></span>
      </div>
    </v-tooltip>
    <div v-else class="day-lock d-flex" dark>
      <v-icon x-small>$clock</v-icon>
      <p class="not-available-description" v-html="dayLocks.longText"></p>
    </div>
  </div>
</template>
<style scoped lang="scss">
.cart-item {
  .day-lock i {
    font-size: 18px !important;
  }
  .not-available-description {
    font-size: 11px;
  }
}
.day-lock {
  font-weight: 800;
  font-size: 11px;
  text-transform: uppercase;
  // background-color: $day-lock-color;
  // color: $white;
  // padding: 4px;
  // border-radius: 4px;
  // text-decoration: line-through;
  // margin-right: 5px;
  i {
    color: $text-color !important;
    font-size: 28px !important;
  }
}
.tooltip-description {
  max-width: 350px;
}
.not-available-description {
  font-size: 13px;
  line-height: 1.1;
  text-transform: none;
  font-weight: 200;
  margin: 0px 6px 0px 6px;
  padding: 0px;
  display: flex;
  align-items: center;
}
</style>
<script>
import split from "lodash/split";
export default {
  name: "ProductDayLock",
  props: ["days", "showTooltip", "isCheckout"],
  computed: {
    dayLocks() {
      //aggiornamento: a differenza di quanto detto in call, lo split va fatto per " " o per ","
      let array = split(this.days, " ");
      // patch for the new version
      if (this.days.indexOf(",") > -1) {
        array = split(this.days, ",");
      }
      console.log(this.isCheckout);
      var result = [];
      result.longText = this.$t("dayLock.overlayText");
      for (var i = 0; i < array.length; i++) {
        result[i] = {};
        if (array[i].indexOf("_") > -1) {
          var daySplitted = array[i].split("_");

          result.longText +=
            this.$t("dayLock.weekDays." + daySplitted[0]) +
            " " +
            this.$t("dayLock.timeDay." + daySplitted[1]);
        } else {
          result.longText += this.$t("dayLock.weekDays." + array[i]);
        }
        result.longText += i == array.length - 1 ? "." : ", ";
      }
      return result;
    }
  }
};
</script>
