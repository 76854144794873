<template><div></div></template>
<script>
export default {
  name: "CookieBot",
  data() {
    return {
      userAgent: navigator.userAgent,
      scriptSrc: "https://consent.cookiebot.com/uc.js"
    };
  },
  mounted() {
    if (!this.isCrawlingBot(this.userAgent)) {
      this.loadCookiebotScript();
    }
  },
  methods: {
    isCrawlingBot(userAgent) {
      const crawlingBotsRegex = /^(TelegramBot|Instagram|WhatsApp|semrushbot|Googlebot|bingbot|YandexBot|Baiduspider|facebookexternalhit|Twitterbot|rogerbot|linkedinbot|Embedly|Quora Link Preview|ShowyouBot|outbrain|pinterest|Slackbot|VKShare|W3C_Validator|Applebot|Slurp|Sogou|msnbot|ia_archiver|BingPreview|adidxbot|DotBot|BingPreview|ezooms|CCBot|Prerender|GTmetrix)$/i;
      return crawlingBotsRegex.test(userAgent);
    },
    loadCookiebotScript() {
      const script = document.createElement("script");
      script.id = "Cookiebot";
      script.src = "https://consent.cookiebot.com/uc.js";
      script.setAttribute("data-cbid", process.env.VUE_APP_COOKIEBOT_CBID);
      script.type = "text/javascript";
      script.async = true;
      document.body.appendChild(script);
    }
  }
};
</script>
