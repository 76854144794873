import router from "./router";
import "./plugins";
import "./config";
import "vuetify-dialog/dist/vuetify-dialog.css";

import App from "./App.vue";
import TextHighlight from "vue-text-highlight";
import Vue from "vue";
import VuetifyDialog from "vuetify-dialog";

import i18n from "./plugins/i18n";
import store from "./store";
import vuetify from "./plugins/vuetify";

import { showToaster, showConfirm, showNotification } from "~/notifications";

import "./scss/app.scss";

Vue.config.productionTip = false;

global.EventBus = new Vue({
  i18n
});

Vue.component("text-highlight", TextHighlight);

Vue.use(VuetifyDialog, {
  context: {
    vuetify,
    store,
    router,
    i18n
  },
  confirm: {
    actions: {
      false: "No",
      true: {
        text: "Si",
        color: "primary"
      }
    },
    icon: true
  }
});

Vue.mixin({
  data() {
    // eslint-disable-next-line no-debugger
    return {
      cordovaPlatform: process.env.CORDOVA_PLATFORM,
      isCordova: typeof process.env.CORDOVA_PLATFORM != "undefined"
    };
  },
  computed: {
    //@deprecated use isCordova instead
    $platform_is_cordova: () => typeof cordova !== "undefined"
  }
});

Vue.prototype.$dialog.on("shown", ({ dialog }) => {
  global.config.dialogs.push(dialog);
});

Vue.prototype.$dialog.on("destroyed", ({ dialog }) => {
  global.config.dialogs = global.config.dialogs.filter(
    _dialog => _dialog.id !== dialog.id
  );
});

Vue.prototype.$dialog.clearDialogs = () => {
  global.config.dialogs.forEach(_dialog => _dialog.remove());
  global.config.dialogs = [];
};

const vm = new Vue({
  router,
  store,
  vuetify,
  i18n,

  render: h => h(App)
}).$mount("#app");

global.vm = vm;

const toastedOption = {
  position: "bottom-center",
  duration: 3000,
  iconPack: "mdi",
  theme: "bubble",
  keepOnHover: true,
  action: {
    icon: "mdi-close",
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    }
  },
  bottom: true
};
global.EventBus.$on("error", payload => {
  if (!payload.title) payload.title = "Si è verificato un errore";
  showToaster("error", payload, toastedOption);
});
global.EventBus.$on("warning", payload => {
  if (!payload.title) payload.title = "Attenzione";
  showToaster("warning", payload, toastedOption);
});
global.EventBus.$on("success", payload => {
  if (!payload.title) payload.title = "Ok";
  showToaster("success", payload, toastedOption);
});

global.EventBus.$on("confirm", showConfirm);

global.EventBus.$on("notification", showNotification);

window.handleOpenURL = function(url) {
  setTimeout(() => {
    console.log("::::: window.handleOpenURL ::::: ", url);
    console.log("::::: window.handleOpenURL store ::::: ", store);
    store.dispatch("handleOpenUrl", {
      url: url
    });
  });
};
