import { handleCategoryNavigation } from "./routeFunctions";

let routes = [
  {
    path: "/checkout",
    name: "Checkout",
    meta: {
      requiresAuth: true
    },
    component: () => import("@/views/checkout/Checkout.vue"),
    beforeEnter(to, from, next) {
      handleCategoryNavigation("checkout", to, from, next);
    }
  },
  {
    path: "/payment",
    name: "Payment",
    meta: {
      requiresAuth: true
    },
    component: () => import("@/views/checkout/Payment.vue"),
    beforeEnter(to, from, next) {
      handleCategoryNavigation("payment", to, from, next);
    }
  },
  {
    path: "/cart/payment-error/:orderId",
    name: "PaymentError",
    meta: {
      requiresAuth: true
    },
    props: true,
    component: () => import("@/views/checkout/PaymentCompleted.vue"),
    beforeEnter(to, from, next) {
      handleCategoryNavigation("payment-error", to, from, next);
    }
  },
  {
    path: "/cart/payment-completed/:orderId",
    name: "PaymentCompleted",
    meta: {
      requiresAuth: true
    },
    props: true,
    component: () => import("@/views/checkout/PaymentCompleted.vue"),
    beforeEnter(to, from, next) {
      handleCategoryNavigation("payment-completed", to, from, next);
    }
  }
];

export default routes;
