import Vue from "vue";
import { VueReCaptcha } from "vue-recaptcha-v3";

// For more options see below
if (process.env.VUE_APP_RECAPTCHA_SITE_KEY) {
  Vue.use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
    loaderOptions: { size: "invisible", badge: "bottomleft" }
  });
}
