<template>
  <v-container class="address-selector fill-height mt-1">
    <v-layout fill-height column mt-5>
      <h3
        class="title font-weight-bold mt-3"
        v-if="category && category.metaData && category.metaData.category_info"
      >
        {{ category.metaData.category_info.TITLE }}
      </h3>
      <p
        class="selector-subitle"
        v-html="category.metaData.category_info.DESCRIPTION"
        v-if="
          category &&
            category.metaData &&
            category.metaData.category_info &&
            category.metaData.category_info.DESCRIPTION
        "
      ></p>
      <v-divider></v-divider>
      <!-- v-model="deliveryService" -->
      <v-tabs
        icons-and-text
        hide-slider
        center-active
        :height="$vuetify.breakpoint.smAndDown ? 100 : 110"
        show-arrows
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab
          v-for="service in deliveryServices"
          :key="service.deliveryServiceId"
          @click="fetchAddresses(service)"
          v-model="tab"
        >
          <div
            class="address-div d-flex flex-column align-center justify-center flex-grow-1"
          >
            <img
              class="mb-1"
              height="50"
              width="70"
              :src="getServiceImg(service.deliveryServiceId)"
            />

            <span
              :class="{
                selected:
                  deliveryService.deliveryServiceId ===
                  service.deliveryServiceId
              }"
              >{{ service.name }}</span
            >
          </div>
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>
      <div class="service-description" v-html="deliveryService.descr"></div>
      <v-card :loading="loading" flat>
        <v-row>
          <v-col
            cols="12"
            sm="4"
            v-if="deliveryService.deliveryServiceId === 2"
          >
            <v-btn
              color="primary"
              large
              depressed
              @click="openAddAddress()"
              class="add-address"
            >
              AGGIUNGI INDIRIZZO
            </v-btn>
          </v-col>
          <v-col
            :cols="12"
            :sm="deliveryService.deliveryServiceId === 2 ? 8 : 12"
          >
            <v-text-field
              class="searchbar"
              hide-details
              v-model="searchText"
              clearable
              outlined
              flat
              :label="
                $t(
                  'navbar.service.' + selectedDeliveryServiceId + '.searchLabel'
                )
              "
            >
              <v-icon slot="append" color="red">
                $search
              </v-icon>
            </v-text-field>
          </v-col>
        </v-row>
        <v-list dense class="address-list">
          <v-list-item-group v-model="shippingAddress" color="primary">
            <v-list-item
              two-line
              v-for="address in filteredAddresses"
              :key="address.addressId"
              @click="setAddress(address)"
            >
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  {{
                    facilitatedAddress &&
                    facilitatedAddress.addressId == address.addressId
                      ? address.addressName + " (Consegna Agevolata)"
                      : address.addressName
                  }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{
                    $t(
                      "navbar.address." + address.addressTypeId + ".list",
                      address
                    )
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action class="flex-row align-center">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      v-if="
                        address.addressId ==
                          cart.user.defaultStoreAddress.addressId ||
                          address.addressId ==
                            cart.user.defaultDeliveryAddressId
                      "
                      >mdi-star-outline</v-icon
                    >
                  </template>
                  <span>{{
                    address.addressType == "home"
                      ? "Indirizzo preferito"
                      : "Negozio preferito"
                  }}</span>
                </v-tooltip>
                <v-tooltip
                  left
                  v-if="
                    address.addressType == 'home' &&
                      facilitatedAddress &&
                      facilitatedAddress.addressId == address.addressId
                  "
                >
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      <v-btn icon @click.stop="edit(address)" disabled>
                        <v-icon>$edit</v-icon>
                      </v-btn>
                    </span>
                  </template>
                  <span
                    >L’indirizzo non è modificabile in quanto è associato alle
                    consegne agevolate.</span
                  >
                </v-tooltip>
                <v-btn
                  icon
                  @click.stop="edit(address)"
                  v-if="
                    (address.addressType == 'home' && !facilitatedAddress) ||
                      (address.addressType == 'home' &&
                        facilitatedAddress &&
                        facilitatedAddress.addressId != address.addressId)
                  "
                >
                  <v-icon>$edit</v-icon>
                </v-btn>
                <v-btn
                  icon
                  @click.stop="remove(address)"
                  v-if="
                    (address.addressType == 'home' && !facilitatedAddress) ||
                      (address.addressType == 'home' &&
                        facilitatedAddress &&
                        facilitatedAddress.addressId != address.addressId)
                  "
                >
                  <v-icon>$delete</v-icon>
                </v-btn>
                <v-tooltip
                  left
                  v-if="
                    address.addressType == 'home' &&
                      facilitatedAddress &&
                      facilitatedAddress.addressId == address.addressId
                  "
                >
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      <v-btn icon @click.stop="remove(address)" disabled>
                        <v-icon>$delete</v-icon>
                      </v-btn>
                    </span>
                  </template>
                  <span
                    >L’indirizzo non può essere eliminato in quanto è associato
                    alle consegne agevolate.</span
                  >
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-layout>
  </v-container>
</template>
<style scoped lang="scss"></style>
<script>
// @ is an alias to /src
import StoreService from "~/service/storeService";
import DeliveryService from "~/service/deliveryService";
import CategoryService from "~/service/categoryService";
import IperalCustomService from "@/service/iperalCustomService";
import { mapState, mapGetters } from "vuex";

export default {
  name: "AddressSelector",
  data() {
    return {
      store: {},
      addresses: [],
      loading: null,
      shippingAddress: null,
      deliveryService: null,
      tab: 2,
      searchText: null,
      category: {},
      facilitatedInfoRead: false
    };
  },
  props: {
    deliveryServiceId: { type: Number, required: false }
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    }),
    filteredAddresses() {
      if (this.searchText) {
        return this.addresses.filter(address => {
          return (
            address.addressName
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) > -1 ||
            address.address1
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) > -1 ||
            address.city.toLowerCase().indexOf(this.searchText.toLowerCase()) >
              -1
          );
        });
      } else {
        return this.addresses;
      }
    },
    ...mapState({
      cart: ({ cart }) => cart.cart,
      selectedService: ({ cart }) => cart.selectedService
    }),
    selectedDeliveryServiceId() {
      return this.deliveryService ? this.deliveryService.deliveryServiceId : 0;
    },
    deliveryServices() {
      if (this.store && this.store.deliveryServices) {
        return this.store.deliveryServices.filter(button => {
          return button.showTab;
        });
      }
      return [];
    },
    facilitatedAddressEnabled() {
      let facilitatedLegalFound = false;
      for (let i = 0; i < this.user.legals.length; i++) {
        if ([4].includes(this.user.legals[i].legalId)) {
          facilitatedLegalFound = true;
          break;
        }
      }
      return facilitatedLegalFound;
    },
    facilitatedAddress() {
      var _this = this;
      console.log(_this);
      let found = this.filteredAddresses
        .map(function(address) {
          // Does current address has any type '999' class?
          let addressClasses = address.addressClass ? address.addressClass : [];
          let facilitatedEnabled = addressClasses.reduce(function(
            total,
            currentValue
          ) {
            return total || currentValue["address-class-id"] === 999;
          },
          false);
          return {
            addressId: address.addressId,
            addressName: address.addressName,
            facilitatedEnabled: facilitatedEnabled
          };
        })
        .filter(function(address) {
          return address.facilitatedEnabled;
        })
        .shift();
      return found ? found : null;
    }
  },
  methods: {
    async setAsfacilitatedAddress(address) {
      // Try to select address class
      try {
        this.loading = true;
        let res = await IperalCustomService.selectAddressClass(
          address.addressId,
          999
        );
        console.log("selectAddressClass", res);
        if (res.response.status == 0) {
          this.loading = false;
          this.setAddress(address);
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    async remove(address) {
      let _this = this;
      const res = await _this.$dialog.confirm({
        text: global.vm.$t("message.removeAddress")
      });
      if (res) {
        let response = await DeliveryService.removeAddress(address.addressId);
        _this.addresses = response.addresses;
      }
    },
    edit(address) {
      let _this = this;
      // _this.address = address;
      _this.$router.push({
        path: "/profile/addresses/manage/" + address.addressId
      });
      _this.$emit("submit", true);
    },
    async fetchStore() {
      let _this = this;
      let store = await StoreService.getStoreById(_this.cart.store.storeId);
      _this.store = store;

      _this.store.deliveryServices.find(deliveryService => {
        if (this.deliveryServiceId) {
          if (deliveryService.deliveryServiceId == _this.deliveryServiceId) {
            _this.deliveryService = deliveryService;
          }
        } else if (
          deliveryService.deliveryServiceId ==
          _this.cart.shippingAddress.deliveryServiceId
        ) {
          _this.deliveryService = deliveryService;
        }
      });
    },
    async fetchAddresses(deliveryService) {
      let _this = this;
      _this.loading = true;
      _this.searchText = null;
      let deliveryServiceId =
        deliveryService.deliveryServiceId || deliveryService;
      _this.deliveryService = deliveryService;
      _this.addresses = await StoreService.getAddressesByDeliveryService(
        deliveryServiceId
      );

      _this.addresses.find((address, index) => {
        if (address.addressId == _this.cart.shippingAddress.addressId) {
          _this.shippingAddress = index;
        }
      });
      _this.loading = false;
    },
    async setAddress(address) {
      let _this = this;
      this.loading = true;
      let data = await this.$store.dispatch("cart/setAddress", {
        addressType: address.addressType,
        addressId: address.addressId
      });

      this.loading = false;
      if (data) {
        _this.$emit("submit", true);
        setTimeout(function() {
          _this.$router.push({ name: "Home" });
        }, 200);
      }
    },
    getServiceImg(serviceId) {
      let _this = this;
      let color =
        serviceId === _this.deliveryService.deliveryServiceId
          ? "color"
          : "grey";
      return this.$t("navbar.service." + serviceId + ".icon", {
        color: color
      });
    },
    openAddAddress() {
      this.$router.push({
        path: "/profile/addresses/manage"
      });
      this.$emit("submit", false);
    },
    async fetchCategory() {
      try {
        this.category = await CategoryService.getCategoryBySlug(
          "service-selector"
        );
      } catch (e) {
        console.log(e);
      }
    }
  },
  created() {
    this.fetchStore();
    this.fetchAddresses(
      this.deliveryServiceId
        ? this.deliveryServiceId
        : this.cart.shippingAddress.deliveryServiceId
    );
    this.fetchCategory();
    console.log(this.deliveryServiceId);
  }
};
</script>
