import { req, len } from "./commons";

function requiredValue(error) {
  var errorMessage = error ? error : "Campo obbligatorio";
  return function(v) {
    return !!v || errorMessage;
  };
}
function isGenericPhone(error) {
  //(HomePhone | CellPhoneIta | CellPhoneCh)
  var errorMessage = error ? error : "Digitare un numero di telefono valido";
  return function(v) {
    return (
      /(^[0-9]{2,4}(\s)?[0-9]{3,6}$)|(^(([+]|00)\d{2})?([\s-])?(((3[0-9][0-9]))?(\d{7})|(\d{2})\s*(\d{3})\s*(\d{2})\s*(\d{2}))$)/i.test(
        v
      ) || errorMessage
    );
  };
}
function isMobilePhone(error) {
  // CellPhoneIta | CellPhoneCh
  var errorMessage = error ? error : "Digitare un numero di telefono valido";
  return function(v) {
    return (
      !req(v) ||
      /(^(([+]|00)\d{2})?([\s-])?(((3[0-9][0-9]))?(\d{7})|(\d{2})\s*(\d{3})\s*(\d{2})\s*(\d{2}))$)/i.test(
        v
      ) ||
      errorMessage
    );
  };
}
function isHomePhone(error) {
  //(HomePhone
  var errorMessage = error ? error : "Digitare un numero di telefono valido";
  return function(v) {
    return !req(v) || /(^[0-9]{2,4}(\s)?[0-9]{3,6}$)/i.test(v) || errorMessage;
  };
}
function isCAP(error) {
  var errorMessage = error ? error : "Il CAP deve essere di 4 o 5 cifre";
  return function(v) {
    return /^[0-9]{4,5}$/.test(v) || errorMessage;
  };
}
function isIstatCode(error) {
  var errorMessage = error ? error : "Lo ZIP Code deve avere 10 cifre";
  return function(v) {
    return /^[0-9]{10}$/.test(v) || errorMessage;
  };
}
function isPasswordMinimumLength(error) {
  console.log(error);
  var errorMessage = error
    ? error
    : "La password deve essere lunga 6 caratteri";
  return function(v) {
    return /.{6,}/.test(v) || errorMessage;
  };
}
function isPasswordMinimumLengthIperal(error) {
  console.log(error);
  var errorMessage = error
    ? error
    : "La password deve essere lunga 8 caratteri";
  return function(v) {
    return /.{8,}/.test(v) || errorMessage;
  };
}
function isEmail(error) {
  var errorMessage = error ? error : "Digitare una e-mail valida";
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  //const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
  return function(v) {
    return !req(v) || emailRegex.test(v) || errorMessage;
  };
}
//iperal is without the +
function isEmailIperal(error) {
  var errorMessage = error ? error : "Digitare una e-mail valida";
  const emailRegex = /^[a-zA-Z0-9._%]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,11}$/;
  //const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
  return function(v) {
    return !req(v) || emailRegex.test(v) || errorMessage;
  };
}
function isCF(error) {
  var errorMessage = error ? error : "Formato non corretto";
  return function(v) {
    return (
      /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/.test(
        v.toUpperCase()
      ) || errorMessage
    );
  };
}
function isCfIva(error) {
  var errorMessage = error ? error : "P.Iva e C.F. devono avere 11 cifre";
  return function(v) {
    return /^[0-9]{11}$/.test(v) || errorMessage;
  };
}
function isSDICode(error) {
  var errorMessage = error ? error : "Codice SDI non valido";
  return function(v) {
    return /^[A-Z0-9]{6,7}$/.test(v) || errorMessage;
  };
}
function isDate(error) {
  var errorMessage = error ? error : "Formato della data gg/mm/aaaa";
  return function(v) {
    return (
      /^(0[1-9]|1[012])[- /.] (0[1-9]|[12][0-9]|3[01])[- /.] (19|20)\d\d$/.test(
        v
      ) || errorMessage
    );
  };
}
function isAlpha(error) {
  //Accepts only alphabet characters.
  var errorMessage = error ? error : "Accetta solo caratteri dell'alfabeto";
  return function(v) {
    return !req(v) || /^[a-zA-Z]*$/.test(v) || errorMessage;
  };
}
function isCity(error) {
  //Accepts only alphabet characters.
  var errorMessage = error
    ? error
    : "Accetta solo lettere, spazi e l'apostrofo";
  return function(v) {
    return !req(v) || /^[a-zA-Z\s'-]*$/.test(v.Nome) || errorMessage;
  };
}
function isAlphaNum(error) {
  //Accepts only alphanumerics.
  var errorMessage = error ? error : "Accetta solo caratteri alfanumerici";
  return function(v) {
    return !req(v) || /^[a-zA-Z0-9]*$/.test(v) || errorMessage;
  };
}
function isInteger(error) {
  var errorMessage = error ? error : "Accetta solo numeri interi";
  return function(v) {
    return !req(v) || /(^[0-9]*$)|(^-[0-9]+$)/.test(v) || errorMessage;
  };
}
function isNumber(error) {
  var errorMessage = error ? error : "Accetta solo numeri";
  return function(v) {
    return !req(v) || /^[0-9]*$/.test(v) || errorMessage;
  };
}
function isLength(length, error) {
  var errorMessage = error
    ? error
    : "Il campo deve essere lungo " + length + "caratteri";
  var l = length;
  return function(v) {
    return !req(v) || len(v) == l || errorMessage;
  };
}
function minLength(length, error) {
  var errorMessage = error ? error : "Lunghezza minima" + length + "caratteri";
  var l = length;
  return function(v) {
    return !req(v) || len(v) >= l || errorMessage;
  };
}
function maxLength(length, error) {
  var errorMessage = error ? error : "Lunghezza massima" + length + "caratteri";
  var l = length;
  return function(v) {
    return !req(v) || len(v) <= l || errorMessage;
  };
}
function isUrl(error) {
  var errorMessage = error ? error : "Digitare un url valido";
  return function(v) {
    return (
      /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
        v
      ) || errorMessage
    );
  };
}

export {
  requiredValue,
  isGenericPhone,
  isMobilePhone,
  isHomePhone,
  isCAP,
  isIstatCode,
  isPasswordMinimumLength,
  isPasswordMinimumLengthIperal,
  isEmailIperal,
  isEmail,
  isCF,
  isCfIva,
  isDate,
  isSDICode,
  isCity,
  isAlpha,
  isAlphaNum,
  isInteger,
  isNumber,
  isUrl,
  isLength,
  minLength,
  maxLength
};
