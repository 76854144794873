<template>
  <v-list flat subheader dense class="py-0">
    <v-list-item>
      <v-list-item-avatar
        class="mr-1 my-1 d-md-none d-lg-inline d-xl-inline"
        rounded="0"
      >
        <img aria-hidden :src="serviceImg" :alt="serviceName" />
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title v-if="!isCart">
          <span class="service-name">{{ serviceName }}</span>
        </v-list-item-title>
        <v-list-item-subtitle class="d-flex flex-column align-left">
          <v-btn
            class="cart-info-button"
            :disabled="!editableAddress"
            text
            tile
            small
            elevation="0"
            rounded
            @click="openAddressSelector"
          >
            <span class="cart-info-label">{{ shippingAddress }} </span>
            <v-icon color="primary" right>$edit</v-icon>
          </v-btn>
          <v-btn
            class="cart-info-button"
            :disabled="!editableTimeslot"
            text
            tile
            small
            elevation="0"
            rounded
            @click="openTimeslotSelector"
          >
            <span class="cart-info-label">{{ timeslot }}</span>
            <v-icon color="primary" right>$edit</v-icon></v-btn
          >
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>
<style lang="scss" scoped>
.service-name {
  padding: 0 8px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: #583d3e;
}
.cart-info-button {
  text-transform: none !important;
  justify-content: left;
  padding: 0 8px !important;
  font-size: 13px;
  height: 16px !important;
  letter-spacing: normal;
  .v-btn__content {
    max-width: 225px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .v-icon {
    font-size: 12px;
    height: 12px;
    width: 12px;
  }
}
.cart-info-label {
  max-width: 212px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.v-list-item--dense .v-list-item__content,
.v-list--dense .v-list-item .v-list-item__content {
  padding: 1px 0;
}
</style>
<script>
import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
export default {
  name: "CartInfoList",
  mixins: [login, cartInfo],
  data() {
    return {
      maxWidth: 320,
      maxHeight: 60
    };
  },
  props: { isCart: { type: Boolean, required: false } }
};
</script>
