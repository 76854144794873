import store from "@/store";

async function handleCategoryNavigation(slug, to, from, next) {
  let category = await store.dispatch("category/setCategory", { slug });
  if (category) {
    next();
  } else {
    if (!navigator.onLine) {
      next("/no-connection");
    } else {
      next("/not-found");
    }
  }
}

export { handleCategoryNavigation };
