var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"address-selector fill-height mt-1"},[_c('v-layout',{attrs:{"fill-height":"","column":"","mt-5":""}},[(_vm.category && _vm.category.metaData && _vm.category.metaData.category_info)?_c('h3',{staticClass:"title font-weight-bold mt-3"},[_vm._v(" "+_vm._s(_vm.category.metaData.category_info.TITLE)+" ")]):_vm._e(),(
        _vm.category &&
          _vm.category.metaData &&
          _vm.category.metaData.category_info &&
          _vm.category.metaData.category_info.DESCRIPTION
      )?_c('p',{staticClass:"selector-subitle",domProps:{"innerHTML":_vm._s(_vm.category.metaData.category_info.DESCRIPTION)}}):_vm._e(),_c('v-divider'),_c('v-tabs',{attrs:{"icons-and-text":"","hide-slider":"","center-active":"","height":_vm.$vuetify.breakpoint.smAndDown ? 100 : 110,"show-arrows":""}},[_c('v-tabs-slider'),_vm._l((_vm.deliveryServices),function(service){return _c('v-tab',{key:service.deliveryServiceId,on:{"click":function($event){return _vm.fetchAddresses(service)}},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('div',{staticClass:"address-div d-flex flex-column align-center justify-center flex-grow-1"},[_c('img',{staticClass:"mb-1",attrs:{"height":"50","width":"70","src":_vm.getServiceImg(service.deliveryServiceId)}}),_c('span',{class:{
              selected:
                _vm.deliveryService.deliveryServiceId ===
                service.deliveryServiceId
            }},[_vm._v(_vm._s(service.name))])])])})],2),_c('v-divider'),_c('div',{staticClass:"service-description",domProps:{"innerHTML":_vm._s(_vm.deliveryService.descr)}}),_c('v-card',{attrs:{"loading":_vm.loading,"flat":""}},[_c('v-row',[(_vm.deliveryService.deliveryServiceId === 2)?_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-btn',{staticClass:"add-address",attrs:{"color":"primary","large":"","depressed":""},on:{"click":function($event){return _vm.openAddAddress()}}},[_vm._v(" AGGIUNGI INDIRIZZO ")])],1):_vm._e(),_c('v-col',{attrs:{"cols":12,"sm":_vm.deliveryService.deliveryServiceId === 2 ? 8 : 12}},[_c('v-text-field',{staticClass:"searchbar",attrs:{"hide-details":"","clearable":"","outlined":"","flat":"","label":_vm.$t(
                'navbar.service.' + _vm.selectedDeliveryServiceId + '.searchLabel'
              )},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}},[_c('v-icon',{attrs:{"slot":"append","color":"red"},slot:"append"},[_vm._v(" $search ")])],1)],1)],1),_c('v-list',{staticClass:"address-list",attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.shippingAddress),callback:function ($$v) {_vm.shippingAddress=$$v},expression:"shippingAddress"}},_vm._l((_vm.filteredAddresses),function(address){return _c('v-list-item',{key:address.addressId,attrs:{"two-line":""},on:{"click":function($event){return _vm.setAddress(address)}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.facilitatedAddress && _vm.facilitatedAddress.addressId == address.addressId ? address.addressName + " (Consegna Agevolata)" : address.addressName)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t( "navbar.address." + address.addressTypeId + ".list", address ))+" ")])],1),_c('v-list-item-action',{staticClass:"flex-row align-center"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [(
                      address.addressId ==
                        _vm.cart.user.defaultStoreAddress.addressId ||
                        address.addressId ==
                          _vm.cart.user.defaultDeliveryAddressId
                    )?_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-star-outline")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(address.addressType == "home" ? "Indirizzo preferito" : "Negozio preferito"))])]),(
                  address.addressType == 'home' &&
                    _vm.facilitatedAddress &&
                    _vm.facilitatedAddress.addressId == address.addressId
                )?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{attrs:{"icon":"","disabled":""},on:{"click":function($event){$event.stopPropagation();return _vm.edit(address)}}},[_c('v-icon',[_vm._v("$edit")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("L’indirizzo non è modificabile in quanto è associato alle consegne agevolate.")])]):_vm._e(),(
                  (address.addressType == 'home' && !_vm.facilitatedAddress) ||
                    (address.addressType == 'home' &&
                      _vm.facilitatedAddress &&
                      _vm.facilitatedAddress.addressId != address.addressId)
                )?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.edit(address)}}},[_c('v-icon',[_vm._v("$edit")])],1):_vm._e(),(
                  (address.addressType == 'home' && !_vm.facilitatedAddress) ||
                    (address.addressType == 'home' &&
                      _vm.facilitatedAddress &&
                      _vm.facilitatedAddress.addressId != address.addressId)
                )?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.remove(address)}}},[_c('v-icon',[_vm._v("$delete")])],1):_vm._e(),(
                  address.addressType == 'home' &&
                    _vm.facilitatedAddress &&
                    _vm.facilitatedAddress.addressId == address.addressId
                )?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{attrs:{"icon":"","disabled":""},on:{"click":function($event){$event.stopPropagation();return _vm.remove(address)}}},[_c('v-icon',[_vm._v("$delete")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("L’indirizzo non può essere eliminato in quanto è associato alle consegne agevolate.")])]):_vm._e()],1)],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }