var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"timeslot-selector fill-height mt-1"},[_c('v-layout',{attrs:{"fill-height":"","column":"","mt-5":""}},[(_vm.category.metaData && _vm.category.metaData.category_info)?_c('div',{staticClass:"d-flex flex-column align-center justify-center"},[_c('h2',{staticClass:"selector-title"},[_vm._v(" "+_vm._s(_vm.category.metaData.category_info.TITLE)+" ")]),_c('p',{staticClass:"selector-subtitle"},[_c('strong',[_vm._v(" "+_vm._s(_vm.cart.shippingAddress.addressName)+": ")]),_vm._v(" "+_vm._s(_vm.$t( "navbar.address." + _vm.cart.shippingAddress.addressTypeId + ".list", _vm.cart.shippingAddress ))+" ")]),(
          _vm.timeslotRestrictions.underLeadTimeItems ||
            _vm.timeslotRestrictions.dayLock ||
            _vm.timeslotRestrictions.lockCutoff
        )?_c('p',{staticClass:"selector-warning selector-warning-under-general-warning text-center"},[_vm._v(" "+_vm._s(_vm.$t("timeslots.generalWarning"))+" ")]):_vm._e()]):_vm._e(),_c('v-divider'),_c('v-tabs',{attrs:{"hide-slider":"","icons-and-text":"","height":_vm.$vuetify.breakpoint.smAndDown ? 110 : 140,"center-active":"","show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider'),_vm._l((_vm.days),function(timeslotDay){return _c('div',{key:timeslotDay.dateIso},[_c('div',{staticClass:"promo-pill"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTimeslotsPromo(timeslotDay.timeslots)),expression:"showTimeslotsPromo(timeslotDay.timeslots)"}],staticClass:"summary-text px-2 py-1 white--text text-uppercase red-promo rounded-pill"},[_vm._v("PROMO")])]),_c('v-tab',{on:{"click":function($event){return _vm.selectTimeslotDay(timeslotDay)}}},[_c('div',{staticClass:"time-div d-flex flex-column align-center justify-space-around"},[(_vm.$dayjs().isSame(timeslotDay.dateIso, 'day'))?[_c('span',{staticClass:"day-number font-weight-bold"},[_vm._v(" OGGI ")])]:[_c('span',{staticClass:"day-string"},[_vm._v(" "+_vm._s(_vm.$dayjs(timeslotDay.dateIso).format("dddd"))+" ")]),_c('span',{staticClass:"day-number font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$dayjs(timeslotDay.dateIso).format("D"))+" ")]),_c('span',{staticClass:"month"},[_vm._v(" "+_vm._s(_vm.$dayjs(timeslotDay.dateIso).format("MMMM"))+" ")])]],2)])],1)})],2),_c('v-divider'),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.days),function(timeslotDay){return _c('v-tab-item',{key:timeslotDay.dateIso,staticClass:"timeslot-container"},[(
            _vm.cart.shippingAddress.addressType === 'home' &&
              _vm.timeSlotsFull.includes(timeslotDay.dayId)
          )?_c('div',{staticClass:"timeslot-container-delivery d-flex flex-wrap mr-sm-auto align-center mb-2"},[_c('p',{staticClass:"text-justify font-weight-bold mb-0 mr-sm-2"},[_vm._v(" "+_vm._s(_vm.$t("timeslots.homeDeliveryNotAvailable"))+" ")]),_c('span',{staticClass:"font-weight-bold mr-1"},[_vm._v(_vm._s(_vm.$t("timeslots.tryStorePickup")))]),_c('v-btn',{staticClass:"mr-1 font-weight-bold text-uppercase text-decoration-underline px-0",attrs:{"ripple":false,"plain":"","color":"primary"},on:{"click":_vm.openAddressSelector}},[_vm._v(_vm._s(_vm.$t("timeslots.selectStorePickup")))]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("timeslots.freeService")))])],1):_vm._e(),(timeslotDay.timeslots.length > 0)?_c('v-row',{attrs:{"no-gutters":""}},_vm._l((timeslotDay.timeslots),function(timeslot){return _c('v-col',{key:timeslot.timeslotId,attrs:{"cols":timeslot.expanded ? 12 : 6,"md":timeslot.expanded ? 12 : 3,"order":timeslot.expanded ? 0 : 1}},[(
                !timeslot.expanded &&
                  _vm.showTimeslotsPromo(timeslotDay.timeslots)
              )?_c('div',{staticClass:"promo-name-pill"},[(_vm.showTimeslotPromo(timeslot))?_c('span',{staticClass:"summary-text px-2 py-1 white--text red-promo promo-name rounded-pill"},[_vm._v(_vm._s(timeslot.promoName))]):(_vm.showStandardDeliveryTag(timeslot))?_c('span',{staticClass:"summary-text white--text standard-delivery promo-name"},[_vm._v("Consegna standard")]):_vm._e()]):_vm._e(),_c('v-card',{staticClass:"timeslot-card",attrs:{"flat":"","disabled":_vm.disabled}},[_c('div',{staticClass:"timeslot-button d-flex justify-center",class:[
                  timeslot.status,
                  { 'under-lead-time': timeslot.underLeadTime },
                  { 'under-lock-day': timeslot.underLockDay },
                  { 'under-lock-cutoff': timeslot.underLockCutoff },
                  { selected: timeslot.selected },
                  {
                    'expanded-card': timeslot.expanded
                  },
                  {
                    'bottom-border-radius': _vm.showTimeslotsPromo(
                      timeslotDay.timeslots
                    )
                  }
                ],on:{"click":function($event){return _vm.selectTimeslot(timeslot)}}},[(timeslot.expanded)?_c('div',{staticClass:"pa-1 day-number-cnt",class:[
                    {
                      'promo-background':
                        _vm.showTimeslotPromo(timeslot) &&
                        timeslot.promoName &&
                        timeslot.promoName.length > 0
                    }
                  ]},[_c('span',{staticClass:"day-number font-weight-bold"},[_vm._v(" "+_vm._s(timeslot.promoName)+" ")])]):_vm._e(),_c('span',{staticClass:"lock-icons"},[(
                      timeslot.underLeadTime ||
                        timeslot.underLockDay ||
                        timeslot.underLockCutoff
                    )?_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" $clock ")]):_vm._e()],1),_c('span',{staticClass:"timeslot-time",class:{
                    'mb-4': timeslot.expanded && timeslot.status === 'full'
                  }},[_vm._v(_vm._s(timeslot.beginTime)+" - "+_vm._s(timeslot.endTime))]),(timeslot.status === 'intermediate')?_c('span',{staticClass:"text-status"},[_vm._v("FASCIA QUASI ESAURITA")]):_vm._e(),(timeslot.status === 'full')?_c('span',{staticClass:"text-status"},[_vm._v("FASCIA ESAURITA")]):_vm._e()])])],1)}),1):_c('p',[_vm._v(" "+_vm._s(_vm.$t("timeslots.noSlots"))+" ")])],1)}),1),_c('v-row',{staticClass:"legend",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","sm":"3"}},[_c('div',{staticClass:"timeslot-legend"},[_c('div',{staticClass:"marker empty"}),_c('span',[_vm._v("Disponibile")])])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","sm":"3"}},[_c('div',{staticClass:"timeslot-legend"},[_c('div',{staticClass:"marker intermediate"}),_c('span',[_vm._v("Quasi esaurita")])])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","sm":"3"}},[_c('div',{staticClass:"timeslot-legend"},[_c('div',{staticClass:"marker full"}),_c('span',[_vm._v("Non disponibile")])])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","sm":"3"}},[_c('div',{staticClass:"timeslot-legend"},[_c('div',{staticClass:"marker selected"}),_c('span',[_vm._v("Selezionata")])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }