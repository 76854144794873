<template>
  <v-list
    id="cartItemList"
    flat
    subheader
    dense
    class="cart-item-list"
    :class="{ 'checkout-item-list': isCheckout }"
  >
    <!-- three-line -->

    <template v-if="!isCheckout">
      <v-subheader class="cart-item-count">
        <CartItemCount :count="cart.totalItems" />
      </v-subheader>
    </template>

    <div class="cart-items">
      <div
        class="sector"
        v-if="
          isCheckout &&
            (notAvailableCartItems.hasDayLock ||
              notAvailableCartItems.hasLeadTime ||
              notAvailableCartItems.hasLockCutoff)
        "
      >
        <div class="sector-header primary">
          <span class="sector-name not-available-name">{{
            $t("notAvailableCartItems.title")
          }}</span>
        </div>
        <div class="sector-content">
          <span class="px-6 not-available-description caption d-block">
            {{ $t("notAvailableCartItems.subtitle") }}
          </span>
          <div v-if="notAvailableCartItems.hasLeadTime">
            <!-- <span class="px-6 not-available-description caption d-block">
              {{ $t("notAvailableCartItems.leadTime") }}
            </span> -->
            <CartItem
              :item="cartItem"
              v-for="cartItem in notAvailableCartItems.leadTime"
              :key="cartItem.cartItemId"
              :full="full"
              :isCheckout="isCheckout"
              :acceptAlternatives="acceptAlternatives"
            />
          </div>
          <div v-if="notAvailableCartItems.hasDayLock">
            <!-- <span class="px-6 not-available-description caption d-block">
              {{ $t("notAvailableCartItems.dayLock") }}
            </span> -->
            <CartItem
              :item="cartItem"
              v-for="cartItem in notAvailableCartItems.dayLock"
              :key="cartItem.cartItemId"
              :full="full"
              :isCheckout="isCheckout"
              :acceptAlternatives="acceptAlternatives"
            />
          </div>
          <div v-if="notAvailableCartItems.hasLockCutoff">
            <!-- <span class="px-6 not-available-description caption d-block">
              {{ $t("notAvailableCartItems.lockCutoff") }}
            </span> -->
            <CartItem
              :item="cartItem"
              v-for="cartItem in notAvailableCartItems.lockCutoff"
              :key="cartItem.cartItemId"
              :full="full"
              :isCheckout="isCheckout"
            />
          </div>
          <span class="d-flex flex-column flex-sm-row justify-center  pa-4">
            <v-btn
              outlined
              color="primary"
              depressed
              class="ma-2"
              @click.stop="removeItems(notAvailableCartItemsComplete)"
            >
              <v-icon left>
                $delete
              </v-icon>
              Elimina i prodotti
            </v-btn>
            <v-btn
              color="primary"
              depressed
              class="ma-2"
              @click.stop="setTimeslot"
            >
              <v-icon left>
                mdi-calendar
              </v-icon>
              Modifica la data di consegna
            </v-btn>
          </span>
        </div>
      </div>
      <div
        class="sector"
        v-for="sector in availableSectors"
        v-bind:key="sector.name"
      >
        <div class="sector-header">
          <span class="sector-name">{{ sector.name }}</span>
          <span class="ml-1 sector-size">({{ sector.size }} prodotti) </span>
          <!-- <v-icon>mdi-chevron-down</v-icon> -->
        </div>
        <div class="sector-content">
          <CartItem
            :item="cartItem"
            v-for="cartItem in sector.cartItems"
            :key="cartItem.cartItemId"
            :full="full"
            :isCheckout="isCheckout"
            :acceptAlternatives="acceptAlternatives"
          />
        </div>
      </div>
    </div>
  </v-list>
</template>
<style scoped lang="scss">
.not-available-description {
  // background-color: var(--v-info-lighten4);
  background-color: var(--v-primary-base);
  color: $white;
  font-size: 0.7rem !important;
}
.sector-header {
  display: flex;
  align-items: center;
  text-align: left;
  min-height: 28px;
  padding: 0 24px;
  font-size: 14px;
  font-weight: bold;
  background-color: #f4f2f0;
  color: #583d3e;
  height: 28px;
  .not-available-name {
    color: $white;
  }
  .sector-name {
    flex: 0 0 auto;
    text-transform: uppercase;
  }
  .sector-size {
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1 1 auto;
  }
}
</style>
<script>
import CartItem from "./CartItem";
import CartItemCount from "./CartItemCount";

import login from "~/mixins/login";
import { mapActions, mapState } from "vuex";
import { forEachCartItem } from "~/service/ebsn";
import get from "lodash/get";

export default {
  name: "CartItemList",
  components: { CartItem, CartItemCount },
  mixins: [login],
  props: ["full", "isCheckout"],
  data() {
    return {
      internalPanel: null
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),

    notAvailableCartItems() {
      let items = {};
      items.leadTime = [];
      items.dayLock = [];
      items.lockCutoff = [];
      forEachCartItem(this.cart, item => {
        if (
          item.cartItemAvaliabilityId == 1 &&
          item.product.productInfos.LEAD_TIME
        ) {
          items.hasLeadTime = true;
          items.leadTime.push(item);
        }
        if (
          item.cartItemAvaliabilityId == 3 &&
          item.product.productInfos.DAY_LOCK
        ) {
          items.hasDayLock = true;
          items.dayLock.push(item);
        }
        if (
          item.cartItemAvaliabilityId == 5 &&
          item.product.productInfos.PRODUCT_LOCK_CUTOFF
        ) {
          items.hasLockCutoff = true;
          items.lockCutoff.push(item);
        }
        if (
          item.cartItemAvaliabilityId == 6 &&
          item.product.productInfos.PRODUCT_AXB_CUTOFF
        ) {
          items.hasLockCutoff = true;
          items.lockCutoff.push(item);
        }
      });
      return items;
    },
    notAvailableCartItemsComplete() {
      let all_items = [];
      forEachCartItem(this.cart, item => {
        if (
          item.cartItemAvaliabilityId == 1 &&
          item.product.productInfos.LEAD_TIME
        ) {
          all_items.push(item);
        }
        if (
          item.cartItemAvaliabilityId == 3 &&
          item.product.productInfos.DAY_LOCK
        ) {
          all_items.push(item);
        }
        if (
          item.cartItemAvaliabilityId == 5 &&
          item.product.productInfos.PRODUCT_LOCK_CUTOFF
        ) {
          all_items.push(item);
        }
        if (
          item.cartItemAvaliabilityId == 6 &&
          item.product.productInfos.PRODUCT_AXB_CUTOFF
        ) {
          all_items.push(item);
        }
      });
      return all_items;
    },
    availableSectors() {
      if (this.isCheckout) {
        let sectors = [];
        this.cart.sectors.forEach(sector => {
          let cartItems = sector.cartItems.filter(
            item => !item.cartItemAvaliabilityId
          );
          if (cartItems.length > 0) {
            sectors.push({
              name: sector.name,
              size: sector.size,
              cartItems: cartItems
            });
          }
        });
        return sectors;
      } else {
        return this.cart.sectors;
      }
    },
    acceptAlternatives: {
      get() {
        let value = false;
        var flaggables = [];
        this.cart.sectors.forEach(sector => {
          sector.cartItems.forEach(cartItem => {
            if (get(cartItem, "product.productInfos.IS_REPLACEABLE")) {
              if (
                get(cartItem, "product.productInfos.IS_REPLACEABLE") == "true"
              ) {
                flaggables.push(cartItem);
              }
              // flaggables.forEach(flaggable => {
              //   if (
              //     get(flaggable, "cartItemInfo.accept_alternatives", "true") ==
              //     "true"
              //   ) {
              //     value = true;
              //   } else {
              //     value = false;
              //     return;
              //   }
              // });
              if (flaggables.length > 0) {
                value = true;
              } else {
                value = false;
              }
            }
            return value;
          });
        });
        return value;
      }
    }
  },
  methods: {
    ...mapActions({ removeItems: "cart/removeItems" })
  }
};
</script>
