// import { handleCategoryNavigation } from "./routeFunctions";
const routes = [
  {
    path: "/page/:pageName",
    name: "Page",

    component: () => import("@/views/wordpress/Page.vue")
  },
  {
    path: "/faq",
    meta: {
      breadCrumb: "Faq"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        name: "FaqCategory",
        path: "",
        component: () => import("@/views/wordpress/FaqCategory.vue")
      },
      {
        path: ":categoryName",
        name: "FaqList",
        meta: {
          breadCrumb: "Dettaglio Faq"
        },
        component: () => import("@/views/wordpress/FaqList.vue")
      }
    ]
  },
  {
    path: "/vantaggi",
    name: "Vantaggi",
    component: () => import("@/views/wordpress/Vantaggi.vue")
  },
  {
    path: "/servizio-clienti",
    name: "Servizio Clienti",
    meta: { breadCrumb: "Servizio Clienti" },
    component: () => import("@/views/wordpress/CustomerReportingForm.vue")
    // beforeEnter(to, from, next) {
    //   handleCategoryNavigation("customer-care", to, from, next);
    // }
  },
  {
    path: "/consegna-agevolata",
    name: "ConsegnaAgevolata",
    meta: {
      breadCrumb: "Consegna agevolata"
    },
    component: () => import("@/views/wordpress/FacilitatedDelivery.vue")
  }
];

export default routes;
