<template>
  <v-card class="py-2 px-3 text-right cart-summary" flat v-if="cart.cartId > 0">
    <v-card-text>
      <PurchaseMinimum></PurchaseMinimum>
      <DeliveryFee></DeliveryFee>
      <div>
        <strong>
          <span class="info-text">
            Totale articoli
          </span>
          <span class="info-value">
            {{ $n(cart.totalPrice, "currency") }}
          </span>
        </strong>
      </div>
      <div>
        <strong>
          <span class="info-text"> Di cui a peso </span>
          <span class="info-value">
            {{ $n(cart.totalPriceVariable, "currency") }}
          </span>
        </strong>
      </div>
      <div v-if="full">
        <div
          v-for="gift in cart.giftCertificates"
          :key="gift.giftCertificateId"
          class="d-flex justify-space-between align-center"
        >
          <strong>{{ gift.description }} pane</strong>
          <v-btn icon @click="removeCode(gift.giftCertificateId)">
            <v-icon color="grey lighten-1">$delete</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="text-h6" v-if="full">
        <strong>
          <span class="info-text">
            Tot. costo sacchetti
          </span>
          <span class="info-value">
            {{ $n(cart.packageTotal, "currency") }}
          </span>
        </strong>
      </div>
      <div v-if="full">
        <div
          v-for="orderPackage in cart.orderPackages"
          :key="orderPackage.orderPackageId"
        >
          <strong>
            <span class="info-text">
              {{ orderPackage.package.descr }}
            </span>
            <span class="info-value">
              ({{ orderPackage.quantity }}x{{
                $n(orderPackage.package.cost, "currency")
              }})
              {{ $n(orderPackage.totalPrice, "currency") }}
            </span>
          </strong>
        </div>

        <div
          v-for="gift in cart.giftCertificatesToGenerate"
          :key="gift.gigftCertificateId"
        >
          <strong>
            <span class="info-text"> {{ gift.description }} pane </span>
            <span class="info-value" v-if="gift.giftTotal">
              {{ $n(gift.giftTotal, "currency") }}
            </span>
          </strong>
        </div>

        <!-- <div class="text-subtitle-1" v-if="cart.deliveryFee > 0">
          <strong>
            <span class="info-text">
              Spese di consegna
            </span>
            <span class="info-value">
              {{ $n(cart.deliveryFee, "currency") }}
            </span>
          </strong>
        </div> -->
      </div>
      <div class="text-h4">
        <strong class="total-value">
          <span>TOTALE </span>
          <span class="total-info-value">
            {{ $n(cart.totalPrice, "currency") }}
          </span>
        </strong>
      </div>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-row no-gutters>
        <v-col sm="12" md="6" class="pa-1" v-if="cart.totalItems > 0">
          <v-btn
            class="bg-white"
            x-large
            outlined
            color="primary"
            depressed
            min-width="95%"
            v-on:click="emptyCartConfirm"
            v-if="!full"
            >Svuota carrello</v-btn
          >
        </v-col>
        <v-col sm="12" md="6" class="pa-1" v-if="cart.totalItems > 0">
          <v-btn
            class="checkout-btn"
            to="/checkout"
            color="primary"
            min-width="95%"
            x-large
            depressed
            >Concludi ordine</v-btn
          >
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
  <v-card v-else>{{ $t("no-cart") }}</v-card>
</template>
<style scoped lang="scss">
// .cart-summary {
//   background-color: $background-color-grey !important;
// }
.v-navigation-drawer {
  .cart-summary {
    position: fixed;
    bottom: 0;
    width: 100%;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles go here */
      position: absolute;
    }
  }
}
</style>
<script>
import DeliveryFee from "@/views/checkout/DeliveryFee.vue";
import PurchaseMinimum from "@/views/checkout/PurchaseMinimum.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "CartSummary",
  components: { PurchaseMinimum, DeliveryFee },
  props: ["full"],
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    })
  },
  methods: {
    ...mapActions({
      emptyCart: "cart/emptyCart"
    }),
    ...mapActions({
      removeGiftCode: "cart/removeGiftCode"
    }),
    removeCode(giftCodeId) {
      this.removeGiftCode(giftCodeId);
    },
    async emptyCartConfirm() {
      if (this.cart.totalItems > 0) {
        let res = await global.vm.$dialog.confirm({
          text: global.vm.$t("message.emptyCart")
        });
        if (res) {
          this.emptyCart();
        }
      }
    },
    changeService() {},
    changeTimeslot() {},
    changeDelivery() {}
  }
};
</script>
