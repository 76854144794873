<template>
  <div
    class="d-flex not-available-product"
    :class="{ 'is-checkout': isCheckout }"
  >
    <div v-if="showTooltip && !isCheckout">
      <v-tooltip bottom v-for="(hour, index) in hourLocks" :key="index">
        <template v-if="hour.isOppositeHours" v-slot:activator="{ on, attrs }">
          <div
            class="hour-lock available-range"
            dark
            v-bind="attrs"
            v-on="on"
            :hour="hour"
            @click.prevent=""
            @mousedown.stop
          >
            <v-icon x-small>$clock</v-icon>
            <!-- <span v-html="hour.shortText"></span> -->
          </div>
        </template>
        <template v-else v-slot:activator="{ on, attrs }">
          <div class="hour-lock" dark v-bind="attrs" v-on="on" :hour="hour">
            <v-icon x-small>$clock</v-icon>
            <!-- <span v-html="hour.shortText"></span> -->
          </div>
        </template>
        <div class="tooltip-description">
          <span v-html="hour.longText"></span>
        </div>
      </v-tooltip>
    </div>
    <div v-else class="hour-lock d-flex">
      <v-icon x-small>$clock</v-icon>
      <div
        class="not-available-text"
        v-for="(hour, index) in hourLocks"
        :key="index"
      >
        <p
          class="not-available-description"
          v-if="hour.isOppositeHours"
          v-html="hour.longText"
        ></p>
        <p v-else class="not-available-description" v-html="hour.longText"></p>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.cart-item {
  .hour-lock i {
    font-size: 18px !important;
  }
  .not-available-description {
    font-size: 11px;
  }
}
.hour-lock {
  font-weight: 800;
  font-size: 11px;
  text-transform: uppercase;
  // background-color: $lock-cutoff-color;
  // color: $text-color;
  // padding: 4px;
  // margin-right: 5px;
  // border-radius: 4px;
  // text-decoration: line-through;
  i {
    color: $text-color !important;
    font-size: 28px !important;
  }
}
.tooltip-description {
  max-width: 350px;
}
.hour-lock.available-range {
  text-decoration: none;
  // background-color: $lock-cutoff-available-range-color;
}
.not-available-text {
  display: flex;
  align-items: center;
}
.not-available-description {
  font-size: 13px;
  line-height: 1.1;
  text-transform: none;
  font-weight: 200;
  margin: 0px 6px 0px 6px;
  padding: 0px;
}
</style>
<script>
import split from "lodash/split";
export default {
  name: "ProductLockCutoff",
  props: ["hours", "showTooltip", "isCheckout"],
  computed: {
    hourLocks() {
      //aggiornamento: a differenza di quanto detto in call, lo split va fatto per " " o per ","
      let array = split(this.hours, " ");
      //array[0] = "10:00-12:00";

      // patch for the new version
      if (this.hours.indexOf(",") > -1) {
        array = split(this.hours, ",");
      }

      var result = [];
      // for (var i = 0; i < array.length; i++) {
      // prendo solo il primo
      for (var i = 0; i < 1; i++) {
        result[i] = {};
        var splittedHours = array[i].split("-");
        var dateStart = this.$dayjs(splittedHours[0], "HH:mm");
        var dateEnd = this.$dayjs(splittedHours[1], "HH:mm");
        if (dateStart.isAfter(dateEnd)) {
          result[i].isOppositeHours = true;
          result[i].shortText = splittedHours[1] + "-" + splittedHours[0];
          let value = this.$dayjs().format("D/MM/YYYY");
          result[i].longText =
            this.$t("lockCutoff.availableRange") +
            value +
            " e nelle fasce orarie " +
            splittedHours[1] +
            "-" +
            splittedHours[0] +
            " dei giorni successivi.";
        } else {
          let value = this.dayjs().format("D/MM/YYYY");
          result[i].isOppositeHours = false;
          result[i].shortText = array[i];
          result[i].longText =
            this.$t("lockCutoff.notAvailableRange") +
            value +
            " e nelle fasce orarie " +
            array[i] +
            " dei giorni successivi.";
        }
      }
      return result;
    }
  }
};
</script>
