<template functional>
  <div class="promo-box-wrapper">
    <div class="promo-box d-flex" :class="[props.warehousePromo.view.cssClass]">
      <span
        v-html="props.warehousePromo.view.header"
        class="promo-header"
      ></span>
      <span
        v-html="props.warehousePromo.view.body"
        class="promo-body d-flex justify-center align-center"
      ></span>
    </div>
    <span
      v-html="props.warehousePromo.view.footer"
      class="ml-2 promo-footer d-block font-weight-bold"
    ></span>
  </div>
</template>
<style lang="scss">
.promo-box {
  position: relative;
  background-color: $red-promo;
  color: #ffffff;
  font-size: 1rem;
  white-space: nowrap;
  border-radius: 9999px;
  padding: 0px 10px;
  font-weight: bold;
  line-height: 1.4;
  width: fit-content;
  flex-grow: initial;
  flex-basis: initial;
  strong {
    font-size: 1.2rem;
    font-weight: bold;
  }
  em {
    font-size: 0.8rem;
  }
  &.promo_risparmio {
    background: white;
    border: 1px solid $red-promo;
    color: $red-promo;
    .promo-body {
      padding-top: 1px;
    }
  }
  &.promo_piubassi {
    border-radius: 0;
    border-top-left-radius: $border-radius-root * 2;
    padding: 4px 6px;
    .promo-body {
      border: 1px solid #fff;
      border-radius: 0;
      border-top-left-radius: $border-radius-root * 2;
      line-height: 1.1;
      padding: 2px 6px 0px 6px;
    }
  }
  &.promo_mxn {
    .promo-body {
      font-size: 20px;
    }
  }
  &.promo_sotto {
    .promo-footer {
      display: block;
      position: absolute;
      bottom: -15px;
      font-size: 12px;
      color: $red-promo;
    }
  }
  &.promo_percent {
    strong {
      margin: 0 4px;
    }
  }
  &.sconti_per_te {
    background-color: rgb(224, 24, 131);
  }
  &.omaggio_oro {
    background-color: #c4a26e;
  }
  &.sconti_per_te,
  &.omaggio_oro {
    .promo-body {
      img {
        width: 16px;
        margin-bottom: -2px;
      }
    }
  }
}
.promo-box-wrapper {
  .promo-footer {
    font-size: 12px;
    color: $red-promo;
  }
}
</style>
<script>
export default {
  name: "ProductPromo",
  props: { warehousePromo: { type: Object, required: true } }
};
</script>
