<template functional>
  <div class="price">
    <template
      v-if="props.product.priceStandardDisplay || props.product.priceDisplay"
    >
      <div
        v-if="
          props.product.warehousePromo &&
            props.product.warehousePromo.warehousePromoTypeId == 1
        "
        class="price-block mxn"
      >
        <div class="promoMxN-price" v-if="props.product.priceStandardDisplay">
          {{ parent.$n(props.product.priceStandardDisplay, "currency") }}
          <span class="other">1 pz</span>
        </div>
        <div
          class="cur-price"
          :class="{ 'promo-price': props.product.priceStandardDisplay }"
          itemprop="price"
        >
          {{
            parent.$n(
              props.product.warehousePromo.view.promoOther.nPrice,
              "currency"
            )
          }}
          <span class="other">
            {{ props.product.warehousePromo.view.promoOther.m }} pz
          </span>
        </div>
      </div>
      <div v-else class="price_block">
        <div
          class="old-price"
          v-if="
            props.product.priceStandardDisplay &&
              props.product.warehousePromo &&
              props.product.warehousePromo.view.cssClass.indexOf(
                'promo_risparmio'
              ) == -1
          "
        >
          {{ parent.$n(props.product.priceStandardDisplay, "currency") }}
        </div>
        <div
          class="cur-price"
          :class="{
            'promo-price':
              props.product.priceStandardDisplay ||
              (props.product.warehousePromo &&
                props.product.warehousePromo.view.cssClass.indexOf(
                  'promo_risparmio'
                ) > -1)
          }"
          itemprop="price"
        >
          {{ parent.$n(props.product.priceDisplay, "currency") }}
          <span
            class="weight-unit"
            v-if="props.product.productInfos.TIPOLOGIA != 'Pezzo'"
            >/{{ props.product.weightUnitDisplay }}</span
          >
        </div>
      </div>
    </template>
  </div>
</template>
<style scoped lang="scss">
.price {
  display: flex;
  flex-direction: column;
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
  color: $text-color;
  white-space: nowrap;
  .weight-unit {
    font-size: 12px;
    margin-left: -5px;
  }
  .old-price {
    font-size: 14px;
    line-height: 1;
    text-decoration: line-through;
  }
  .promoMxN-price {
    font-size: 14px;
    font-weight: normal;
  }
  .other {
    white-space: nowrap;
    font-size: 12px;
    text-decoration: none !important;
  }
  .original {
    font-size: 16px;
  }
}
</style>
<script>
export default {
  name: "props.productPrice",
  props: { product: { type: Object, required: true } }
};
</script>
