<template>
  <div class="py-2 swiper-container-wrapper">
    <swiper :options="swiperOption" ref="swiperRef" v-if="proposals">
      <swiper-slide v-for="proposal in proposals" :key="proposal.id">
        <div class="d-flex flex-column swiper-page">
          <h3 class="title">{{ proposal.descr }}</h3>
          <v-card rounded="lg" elevation="0" class="proposal-img">
            <v-img :src="getImage(proposal)" :alt="proposal.name" />
          </v-card>
          <div class="content" v-html="proposal.content"></div>
        </div>
      </swiper-slide>
      <div
        v-if="proposals.length > 0"
        class="swiper-pagination swiper-pagination-tutorial"
        slot="pagination"
      ></div>
    </swiper>
  </div>
</template>
<style scoped>
.swiper-container,
.swiper-page {
  height: 100%;
  justify-content: space-evenly;
}
.swiper-container-wrapper {
  background-color: black;
  height: 100%;
}
.swiper-pagination-tutorial {
  bottom: 30px;
  width: 100%;
}
.proposal-img {
  background-color: black;
  padding: 25px;
  margin-top: -25px;
  margin-bottom: -25px;
}
.swiper-slide {
  background-color: black;
}
.title {
  font-size: 1.5rem !important;
  font-weight: bold;
}
.content {
  font-size: 17px;
}
.title,
.content {
  color: white;
  text-align: center;
}
/* .swiper-pagination-tutorial {
  bottom: 10px;
  width: 100%;
} */
</style>
<script>
import get from "lodash/get";
export default {
  name: "ProposalTutorialSlider",
  props: {
    proposals: { type: Array, required: true }
  },

  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        autoplay: false,
        freeMode: false,
        observer: true,
        observeParents: true,
        watchOverflow: true,
        pagination: {
          el: ".swiper-pagination-tutorial"
        }
      }
    };
  },
  methods: {
    getImage(proposal) {
      let prop =
        "metaData.category_proposal_type." +
        (!this.$vuetify.breakpoint.xsOnly ? "IMAGE_DESKTOP" : "IMAGE_MOBILE");
      return get(proposal, prop, proposal.img);
    }
  }
};
</script>
