<template>
  <v-list dense class="category-tree">
    <v-list-group
      v-for="category in categories"
      :key="category.categoryId"
      class="category1"
    >
      <template v-slot:activator>
        <v-list-item-title>
          <router-link
            :to="{ name: 'Category', params: { pathMatch: category.slug } }"
            event
            @click.native.prevent="nop"
          >
            {{ category.name }}</router-link
          ></v-list-item-title
        >
      </template>
      <template v-for="category2 in category.categories">
        <v-list-group
          sub-group
          color="#033"
          class="category2"
          v-if="category2.categories"
          :key="category2.categoryId"
        >
          <template v-slot:activator>
            <v-list-item-icon
              v-if="category.metaData && category.metaData.category_info.ICON"
            >
              <v-img :src="category.metaData.category_info.ICON"></v-img>
            </v-list-item-icon>
            <v-list-item-title>
              <router-link
                :to="{
                  name: 'Category',
                  params: { pathMatch: category2.slug }
                }"
                event
                @click.native.prevent="nop"
                >{{ category2.name }}
              </router-link></v-list-item-title
            >
          </template>

          <v-list-item
            v-for="category3 in category2.categories"
            :key="category3.categoryId"
            :to="{ name: 'Category', params: { pathMatch: category3.slug } }"
            color="grey lighten-3"
            class="category3"
          >
            <v-list-item-title>{{ category3.name }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-item
          v-else
          link
          :to="{ name: 'Category', params: { pathMatch: category2.slug } }"
          color="grey lighten-3"
          class="category2"
          :key="category2.categoryId"
        >
          <v-list-item-title>{{ category2.name }}</v-list-item-title>
        </v-list-item>
      </template>
    </v-list-group>
  </v-list>
</template>
<script>
import deliveryReactive from "~/mixins/deliveryReactive";
import { mapState, mapActions } from "vuex";
export default {
  name: "CategoryTree",
  mixins: [deliveryReactive],
  data() {
    return { expandedKeys: [], selectedKey: null };
  },
  computed: {
    ...mapState({
      categories: ({ category }) => category.categoryTree
    })
  },
  methods: {
    ...mapActions({
      reload: "category/loadCategoryTree"
    }),
    nop() {}
  },
  created() {
    this.reload();
  }
};
</script>
