<template>
  <v-container class="timeslot-selector fill-height mt-1">
    <v-layout fill-height column mt-5>
      <div
        class="d-flex flex-column align-center justify-center"
        v-if="category.metaData && category.metaData.category_info"
      >
        <h2 class="selector-title">
          {{ category.metaData.category_info.TITLE }}
        </h2>
        <!-- <h5 class="subtitle-1" v-if="category.metaData.category_info.SUBTITLE">
          {{ category.metaData.category_info.SUBTITLE }}
        </h5> -->
        <!-- <div
          v-if="category.metaData.category_info.DESCRIPTION"
          v-html="category.metaData.category_info.DESCRIPTION"
        ></div> -->
        <p class="selector-subtitle">
          <strong> {{ cart.shippingAddress.addressName }}: </strong>
          {{
            $t(
              "navbar.address." + cart.shippingAddress.addressTypeId + ".list",
              cart.shippingAddress
            )
          }}
          <!-- <v-icon small color="primary" right @click="changeAddress">
            $edit
          </v-icon> -->
        </p>
        <p
          class="selector-warning selector-warning-under-general-warning text-center"
          v-if="
            timeslotRestrictions.underLeadTimeItems ||
              timeslotRestrictions.dayLock ||
              timeslotRestrictions.lockCutoff
          "
        >
          {{ $t("timeslots.generalWarning") }}
        </p>
      </div>
      <v-divider></v-divider>
      <v-tabs
        hide-slider
        icons-and-text
        v-model="tab"
        :height="$vuetify.breakpoint.smAndDown ? 110 : 140"
        center-active
        show-arrows
      >
        <v-tabs-slider></v-tabs-slider>
        <div v-for="timeslotDay in days" :key="timeslotDay.dateIso">
          <div class="promo-pill">
            <span
              class="summary-text px-2 py-1 white--text text-uppercase red-promo rounded-pill"
              v-show="showTimeslotsPromo(timeslotDay.timeslots)"
              >PROMO</span
            >
          </div>
          <v-tab @click="selectTimeslotDay(timeslotDay)">
            <div
              class="time-div d-flex flex-column align-center justify-space-around"
            >
              <template v-if="$dayjs().isSame(timeslotDay.dateIso, 'day')">
                <span class="day-number font-weight-bold">
                  OGGI
                </span>
              </template>
              <template v-else>
                <span class="day-string">
                  {{ $dayjs(timeslotDay.dateIso).format("dddd") }}
                </span>
                <span class="day-number font-weight-bold">
                  {{ $dayjs(timeslotDay.dateIso).format("D") }}
                </span>
                <span class="month">
                  {{ $dayjs(timeslotDay.dateIso).format("MMMM") }}
                </span>
              </template>
            </div>
          </v-tab>
        </div>
      </v-tabs>

      <v-divider></v-divider>

      <v-tabs-items v-model="tab">
        <v-tab-item
          class="timeslot-container"
          v-for="timeslotDay in days"
          :key="timeslotDay.dateIso"
        >
          <div
            class="timeslot-container-delivery d-flex flex-wrap mr-sm-auto align-center mb-2"
            v-if="
              cart.shippingAddress.addressType === 'home' &&
                timeSlotsFull.includes(timeslotDay.dayId)
            "
          >
            <p class="text-justify font-weight-bold mb-0 mr-sm-2">
              {{ $t("timeslots.homeDeliveryNotAvailable") }}
            </p>

            <span class="font-weight-bold mr-1">{{
              $t("timeslots.tryStorePickup")
            }}</span>
            <v-btn
              :ripple="false"
              plain
              color="primary"
              class="mr-1 font-weight-bold text-uppercase text-decoration-underline px-0"
              @click="openAddressSelector"
              >{{ $t("timeslots.selectStorePickup") }}</v-btn
            >
            <span class="font-weight-bold">{{
              $t("timeslots.freeService")
            }}</span>
          </div>
          <v-row v-if="timeslotDay.timeslots.length > 0" no-gutters>
            <v-col
              :cols="timeslot.expanded ? 12 : 6"
              :md="timeslot.expanded ? 12 : 3"
              :order="timeslot.expanded ? 0 : 1"
              v-for="timeslot in timeslotDay.timeslots"
              :key="timeslot.timeslotId"
            >
              <div
                class="promo-name-pill"
                v-if="
                  !timeslot.expanded &&
                    showTimeslotsPromo(timeslotDay.timeslots)
                "
              >
                <span
                  class="summary-text px-2 py-1 white--text red-promo promo-name rounded-pill"
                  v-if="showTimeslotPromo(timeslot)"
                  >{{ timeslot.promoName }}</span
                >
                <span
                  v-else-if="showStandardDeliveryTag(timeslot)"
                  class="summary-text white--text standard-delivery promo-name"
                  >Consegna standard</span
                >
              </div>
              <v-card class="timeslot-card" flat :disabled="disabled">
                <div
                  v-on:click="selectTimeslot(timeslot)"
                  class="timeslot-button d-flex justify-center"
                  :class="[
                    timeslot.status,
                    { 'under-lead-time': timeslot.underLeadTime },
                    { 'under-lock-day': timeslot.underLockDay },
                    { 'under-lock-cutoff': timeslot.underLockCutoff },
                    { selected: timeslot.selected },
                    {
                      'expanded-card': timeslot.expanded
                    },
                    {
                      'bottom-border-radius': showTimeslotsPromo(
                        timeslotDay.timeslots
                      )
                    }
                  ]"
                >
                  <div
                    class="pa-1 day-number-cnt"
                    :class="[
                      {
                        'promo-background':
                          showTimeslotPromo(timeslot) &&
                          timeslot.promoName &&
                          timeslot.promoName.length > 0
                      }
                    ]"
                    v-if="timeslot.expanded"
                  >
                    <span class="day-number font-weight-bold">
                      {{ timeslot.promoName }}
                    </span>
                  </div>
                  <span class="lock-icons">
                    <v-icon
                      v-if="
                        timeslot.underLeadTime ||
                          timeslot.underLockDay ||
                          timeslot.underLockCutoff
                      "
                      x-small
                    >
                      $clock
                    </v-icon>
                  </span>
                  <span
                    class="timeslot-time"
                    :class="{
                      'mb-4': timeslot.expanded && timeslot.status === 'full'
                    }"
                    >{{ timeslot.beginTime }} - {{ timeslot.endTime }}</span
                  >
                  <span
                    v-if="timeslot.status === 'intermediate'"
                    class="text-status"
                    >FASCIA QUASI ESAURITA</span
                  >
                  <span v-if="timeslot.status === 'full'" class="text-status"
                    >FASCIA ESAURITA</span
                  >
                </div>
              </v-card>
            </v-col>
          </v-row>
          <p v-else>
            {{ $t("timeslots.noSlots") }}
          </p>
        </v-tab-item>
      </v-tabs-items>

      <!-- <v-spacer></v-spacer> -->
      <v-row class="legend" no-gutters>
        <v-col cols="6" sm="3" class="py-0">
          <div class="timeslot-legend">
            <div class="marker empty"></div>
            <span>Disponibile</span>
          </div>
        </v-col>
        <v-col cols="6" sm="3" class="py-0">
          <div class="timeslot-legend">
            <div class="marker intermediate"></div>
            <span>Quasi esaurita</span>
          </div>
        </v-col>
        <v-col cols="6" sm="3" class="py-0">
          <div class="timeslot-legend">
            <div class="marker full"></div>
            <span>Non disponibile</span>
          </div>
        </v-col>
        <v-col cols="6" sm="3" class="py-0">
          <div class="timeslot-legend">
            <div class="marker selected"></div>
            <span>Selezionata</span>
          </div>
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>
<style scoped lang="scss">
.timeslot-container-delivery {
  > p,
  button,
  span {
    font-size: 1.125rem;
  }
}
.lock-icons {
  position: absolute;
  top: 0;
  right: 7px;
  .lead-time {
    color: $lead-time-color;
  }
  .day-lock {
    color: $day-lock-color;
  }
  .lock-cutoff {
    color: $lock-cutoff-color;
  }
}

.summary-text.red-promo {
  background-color: $red-promo !important;
  vertical-align: middle;
}

.summary-text.standard-delivery {
  display: block;
  color: $text-color !important;
  background-color: #e7e7e7;
  border: 1px solid #e7e7e7;
  border-radius: 5px 5px 0 0;
  padding: 4px 8px 0px 8px;
}

.promo-name {
  font-size: 13px;
  font-weight: bold;
  vertical-align: sub !important;
}
.promo-pill {
  text-align: center;
  height: 30px;
}
.promo-name-pill {
  text-align: center;
  height: 24px;
  width: 100%;
  padding: 0 4px;
}

.expanded-card {
  width: 100%;
  max-width: 100%;
  padding: 0px !important;
  margin: 0px 0px 10px 0px !important;
  .day-number-cnt {
    width: 100%;
    max-width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-align: center !important;
  }
  .day-number-cnt.promo-background {
    background-color: $red-promo;
  }
  .day-number {
    color: $white;
    font-size: 15px !important;
  }
  .timeslot-time {
    color: $text-color !important;
    font-size: 22px !important;
    font-weight: bold;
  }
}
.expanded-card.selected {
  .timeslot-time {
    color: $white !important;
  }
}
</style>
<script>
// @ is an alias to /src
import DeliveryService from "~/service/deliveryService";
import CategoryService from "~/service/categoryService";
import { forEachCartItem } from "~/service/ebsn";
import { mapState } from "vuex";
import toNumber from "lodash/toNumber";
import isNumber from "lodash/isNumber";
import split from "lodash/split";
import isEqual from "lodash/isEqual";
import get from "lodash/get";
import cartInfo from "~/mixins/cartInfo";

export default {
  name: "TimeslotSelector",
  mixins: [cartInfo],
  data() {
    return {
      days: {},
      tab: 0,
      selectedTimeslotDay: {},
      disabled: false,
      category: {},
      timeslotPromo: [],
      timeslotPromoSelected: {},
      timeSlotsFull: null
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    timeslotRestrictions() {
      var result = {};
      let maxLeadTime = 0;
      forEachCartItem(this.cart, item => {
        let leadTime = toNumber(item.product.productInfos.LEAD_TIME);
        if (isNumber(leadTime) && leadTime > maxLeadTime) {
          maxLeadTime = leadTime;
        }
        result.underLeadTimeItems = maxLeadTime > 0;

        if (item.product.productInfos.DAY_LOCK) {
          if (!result.dayLock) result.dayLock = [];
          let array = split(item.product.productInfos.DAY_LOCK, " ");
          if (item.product.productInfos.DAY_LOCK.indexOf(",") > -1) {
            array = split(item.product.productInfos.DAY_LOCK, ",");
          }
          for (let i = 0; i < array.length; i++) {
            if (array[i].indexOf("_") > -1) {
              // "day_deliveryDayPeriodId, es: lun_2"
              let daySplitted = array[i].split("_");
              result.dayLock.push({
                day: daySplitted[0],
                deliveryDayPeriodId: daySplitted[1]
              });
            } else {
              // "day, es: lun"
              result.dayLock.push({
                day: array[i],
                deliveryDayPeriodId: undefined
              });
            }
          }
        }

        if (item.product.productInfos.PRODUCT_LOCK_CUTOFF) {
          if (!result.lockCutoff) result.lockCutoff = [];
          let arrayLock = split(
            item.product.productInfos.PRODUCT_LOCK_CUTOFF,
            " "
          );
          if (item.product.productInfos.PRODUCT_LOCK_CUTOFF.indexOf(",") > -1) {
            arrayLock = split(
              item.product.productInfos.PRODUCT_LOCK_CUTOFF,
              ","
            );
          }
          // arrayLock[0] = "16:00-18:00";
          for (var i = 0; i < arrayLock.length; i++) {
            let hours = arrayLock[i].split("-");
            result.lockCutoff.push(hours);
          }
        }
      });
      result.leadTimeLimit = this.$dayjs().add(maxLeadTime, "hour");
      // result.dayLock = null;
      return result;
    }
  },
  methods: {
    //setAddress change to object with two values setAddress, deliveryServiceId
    openAddressSelector() {
      this.$emit("submit", { action: "setAddress", deliveryServiceId: 1 });
    },
    checkIfIsToday(date) {
      // var timeslotDate = this.$dayjs(date);
      // return timeslotDate.isSame(this.$dayjs(), "day");
      return this.$dayjs().isSame(date, "day");
    },
    selectTimeslotDay(day) {
      this.selectedTimeslotDay = day;
    },
    async selectTimeslot(timeslot) {
      let _this = this;
      if (timeslot.active < 1) {
        return;
      }
      _this.disabled = true;
      let data = await _this.$store.dispatch("cart/setTimeslot", {
        dateIso: _this.selectedTimeslotDay.dateIso,
        timeslotId: timeslot.timeslotId
      });
      if (data) {
        this.$emit("submit", true);
      }
    },
    async fetchTimeslots() {
      let _this = this;
      _this.days = await DeliveryService.getTimeslotList(true);

      if (_this.days.length > 1 && _this.days[0].timeslots.length == 0) {
        _this.days.shift();
      }

      for (var d = 0; d < _this.days.length; d++) {
        for (var t = 0; t < _this.days[d].timeslots.length; t++) {
          var timeslot = _this.days[d].timeslots[t];
          //TEST
          if (
            timeslot.metaData &&
            timeslot.metaData.timeslot_info &&
            timeslot.metaData.timeslot_info.EXPAND
          ) {
            timeslot.expanded = true;
          } else {
            timeslot.expanded = false;
          }
          if (
            timeslot.userGiftCertificates &&
            timeslot.userGiftCertificates.length > 0
          ) {
            for (var g = 0; g < timeslot.userGiftCertificates.length; g++) {
              if (
                timeslot.userGiftCertificates[g].giftCertificate &&
                timeslot.status != "full"
              ) {
                this.timeslotPromo.push(timeslot);
              }
            }
          }
        }
      }

      for (var i = 0; i < _this.days.length; i++) {
        console.log("NEL FOR!");
        console.log(_this.days[i].dateIso);
        console.log(_this.cart.timeslot.date);
        if (_this.days[i].dateIso == _this.cart.timeslot.date) {
          _this.tab = i;
          this.selectedTimeslotDay = _this.days[i];
          break;
        }
      }
      if (!_this.selectedTimeslotDay.dateIso) {
        _this.selectTimeslotDay(_this.days[0]);
      }
      this.timeSlotsFull = this.days
        .filter(
          obj =>
            obj.timeslots.length > 0 &&
            obj.timeslots.every(slot => slot.status === "full")
        )
        .map(day => day.dayId);
      return false;
    },
    async fetchCategory() {
      try {
        this.category = await CategoryService.getCategoryBySlug(
          "timeslot-selector"
        );
      } catch (e) {
        console.log(e);
      }
    },
    showTimeslotsPromo(timeslots) {
      var result = false;
      for (var i = 0; i < timeslots.length; i++) {
        var timeslot = timeslots[i];
        for (var k = 0; k < this.timeslotPromo.length; k++) {
          if (isEqual(timeslot, this.timeslotPromo[k])) {
            result = true;
            break;
          }
        }
        if (result) {
          break;
        }
      }
      return result;
    },
    showTimeslotPromo(timeslot) {
      var result = false;
      //TEST
      if (
        timeslot.metaData &&
        timeslot.metaData.timeslot_info &&
        timeslot.metaData.timeslot_info.EXPAND
      ) {
        timeslot.expanded = true;
      } else {
        timeslot.expanded = false;
      }
      for (var k = 0; k < this.timeslotPromo.length; k++) {
        if (isEqual(timeslot, this.timeslotPromo[k])) {
          result = true;
          var time = timeslot;
          for (var g = 0; g < time.userGiftCertificates.length; g++) {
            timeslot.promoName =
              time.userGiftCertificates[g].giftCertificate.name;
            // if (
            //   time.metaData &&
            //   time.metaData.timeslot_info &&
            //   time.metaData.timeslot_info.EXPAND
            // ) {
            //   timeslot.expanded = true;
            // } else {
            //   timeslot.expanded = false;
            // }
          }
        }
      }

      return result;
    },
    showStandardDeliveryTag(timeslot) {
      return get(
        timeslot,
        "metaData.timeslot_info.SHOW_STANDARD_DELIVERY_TAG",
        false
      );
    }
  },
  mounted() {
    this.disabled = false;
    this.fetchTimeslots();
    this.fetchCategory();
  }
};
</script>
