<template>
  <div class="login fill-height mt-1">
    <v-container fluid fill-height>
      <v-layout>
        <v-flex xs12 mt-5>
          <v-form ref="form" v-model="valid" @submit.prevent="handleSubmit">
            <v-card class="elevation-0">
              <!-- <v-toolbar class="black--text font-weight-bold" color="white" flat>
              <v-toolbar-title>{{ $t("login.title") }}</v-toolbar-title>
            </v-toolbar> -->
              <div class="d-flex align-center flex-column mb-3">
                <h1 class="login-title">{{ $t("login.title") }}</h1>
                <h3 class="font-weight-regular login-sub">
                  {{ $t("login.subtitle") }}
                </h3>
              </div>
              <v-spacer></v-spacer>
              <v-card-text>
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  :error-messages="errors"
                  type="email"
                  label="Indirizzo email"
                  autocomplete="off"
                  required
                  outlined
                  dense
                  clearable
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  label="Password"
                  autocomplete="off"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  :rules="passwordRules"
                  @click:append="toggleShowPassword"
                  required
                  outlined
                  dense
                  clearable
                ></v-text-field>

                <div
                  class="d-flex align-center justify-space-between remember-password"
                >
                  <v-checkbox
                    color="primary"
                    class="ma-0"
                    hide-details
                    v-model="rememberMe"
                  >
                    <template v-slot:label>
                      <span>Ricordami su questo dispositivo</span>
                    </template>
                  </v-checkbox>
                  <div class="recover-pass-wrap" v-if="enableRegistration">
                    <a
                      @click="goToResetPassword"
                      class="text-decoration-underline recover-password"
                    >
                      {{ $t("login.rememberPassword") }}
                    </a>
                  </div>
                  <div
                    class="recover-pass-wrap"
                    v-else-if="!$platform_is_cordova"
                  >
                    <a
                      href="https://www.iperal.it/Recupera-Password"
                      class="text-decoration-underline recover-password"
                      >{{ $t("login.rememberPassword") }}</a
                    >
                  </div>
                </div>

                <ResponseMessage :response="response" />
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-btn
                      :loading="loading"
                      type="submit"
                      class="login-button"
                      large
                      width="100%"
                      depressed
                    >
                      {{ $t("login.buttonLogin") }}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12">
                    <RecaptchaDisclaimer @click="clicked" />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <div
                  class="d-flex flex-column align-center mt-2 register-wrapper"
                  v-if="enableRegistration"
                >
                  <div class="register-label">
                    {{ $t("login.messageRegister") }}
                  </div>
                  <v-btn
                    @click="handleRegister"
                    class="register-button"
                    large
                    outlined
                    width="100%"
                    depressed
                  >
                    {{ $t("login.buttonRegister") }}
                  </v-btn>
                </div>
                <div
                  class="d-flex flex-column align-center mt-2 register-wrapper"
                  v-else-if="!$platform_is_cordova"
                >
                  <div class="register-label">
                    {{ $t("login.messageRegister") }}
                  </div>
                  <v-btn
                    href="https://www.iperal.it/Registrazione"
                    class="register-button"
                    large
                    outlined
                    width="100%"
                    depressed
                    >{{ $t("login.buttonRegister") }}
                  </v-btn>
                </div>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import ResponseMessage from "@/components/ResponseMessage";
import RecaptchaDisclaimer from "@/commons/components/RecaptchaDisclaimer.vue";
import pushNotification from "~/mixins/pushNotification";
import clickHandler from "~/mixins/clickHandler";
import { mapActions, mapGetters } from "vuex";

import {
  requiredValue,
  isEmail,
  isPasswordMinimumLength
} from "@/validator/validationRules";

export default {
  name: "Login",
  props: {
    hideRegistration: { type: Boolean, required: false, default: false }
  },
  mixins: [pushNotification, clickHandler],
  components: { ResponseMessage, RecaptchaDisclaimer },
  data() {
    return {
      loading: null,
      showPassword: false,
      email: "",
      password: "",
      rememberMe: true,
      response: {},
      valid: true,
      lazy: true,
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      passwordRules: [
        requiredValue("Digitare la password"),
        isPasswordMinimumLength()
      ],
      errors: [],
      invalid: null
    };
  },
  computed: {
    enableRegistration() {
      return global.config.registration;
    }
  },
  methods: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    ...mapActions({
      doLogin: "cart/doLogin",
      loadCart: "cart/loadCart"
    }),
    goToResetPassword() {
      this.$router.push({
        name: "ResetPasswordRequest"
      });
      this.$emit("submit", false);
    },
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    async handleSubmit(e) {
      e.preventDefault();
      if (this.password.length > 0) {
        try {
          this.loading = true;
          this.response = {};
          await this.doLogin({
            email: this.email,
            password: this.password,
            rememberMe: this.rememberMe
          });
          this.$emit("submit", true);
        } catch (response) {
          this.response = response;
        } finally {
          this.loading = false;
        }
      }
    },
    handleRegister() {
      this.$router.push({
        name: "RegistrationUser",
        path: "/registration-user"
      });
      this.$emit("submit", false);
    }
  }
};
</script>
