global.config = {
  google_api_key: process.env.VUE_APP_GOOGLE_API_KEY,
  googleGeocodeParams: {
    components: "country:it|administrative_area:Lombardia"
  },
  baseUrl: "",
  proximityDefaultDistance: "10000.0",
  showSectors: true,
  acceptAlternatives: false,
  deliveryServiceWithAlternativesProduct: [2, 10002],
  showInvoiceForWarehouseId: [89],
  dksId: 89,
  hashNotReload: ["/cart/", "/payment"],
  filterEmptyCategory: true,
  dayWordsSaved: 30,
  version: process.env.VUE_APP_VERSION,
  minProfileLevel: 2,
  registration: true,
  checkVersionInterval: 1000 * 60 * 10, //ogni 10 minuti
  reloadAfterMillis: 1000 * 60 * 30, //dopo 30 minuti di inattività restarta la app
  domainWhitelist: [
    "www.iperalspesaonline.it",
    "test.iperalspesaonline.it",
    "dev-iperal.digitelematica.org",
    "localhost"
  ],
  showInPopupCode: ["msg.cart.availability.errors"],
  dialogs: [],
  pushNotificationEnabled: true,
  urlScheme: "ebsniperal://",
  deeplinks: {
    "/profile/orders": "/profile/orders",
    "/profile/orders/:orderId": "/profile/orders/:orderId",
    "/checkout": "/checkout",
    "/search": "/search",
    "/product/:slug": "/product/:slug",
    "/category/*": "/category/*",
    "/page/:slug": "/page/:slug",
    "/reset-password-request": "/reset-password-request",
    "/reset-password-sent": "/reset-password-sent",
    "/reset-password": "/reset-password",
    "/reset-password-completed": "/reset-password-completed"
  },
  filterProductClasses: [40056, 40057, 40060, 40061, 40062, 40063, 40064],
  updateCart: true,
  defaultStoreName: "Iperal",
  analyticsClass: "analyticsServiceGTM",
  recaptchaTokenEnabled: true,
  recaptchaEnabledEndpoints: [
    "/ebsn/api-iperal/account/update-info-user",
    "/ebsn/api/api-iperal/account/update-user",
    "/ebsn/api/api-iperal/account/user",
    "/ebsn/api/auth/login",
    "/ebsn/api/checkout/buy",
    "/ebsn/api/iperal-registration/password_reset",
    "/ebsn/api/iperal-registration/register_user",
    "/ebsn/api/iperal-registration/update_password"
  ]
};
