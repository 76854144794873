import Vue from "vue";

// Truncate
Vue.filter("truncate", function(text, length = 30, clamp = "...") {
  if (text.length <= length) {
    return text;
  } else {
    return text.substring(0, length) + clamp;
  }
});

// Uppercase
Vue.filter("uppercase", function(value) {
  return value || value === 0 ? value.toString().toUpperCase() : "";
});

// Lowercase
Vue.filter("lowercase", function(value) {
  return value || value === 0 ? value.toString().toLowerCase() : "";
});

// Capitalize
Vue.filter("capitalize", function(value) {
  if (!value && value !== 0) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

// Currency
Vue.filter("currency", function(value, currency = "€", decimals = 2) {
  const digitsRE = /(\d{3})(?=\d)/g;
  value = parseFloat(value);
  if (!isFinite(value) || (!value && value !== 0)) return "";
  const stringified = Math.abs(value).toFixed(decimals);
  const _int = decimals ? stringified.slice(0, -1 - decimals) : stringified;
  const i = _int.length % 3;
  const head = i > 0 ? _int.slice(0, i) + (_int.length > 3 ? "," : "") : "";
  const _float = decimals ? stringified.slice(-1 - decimals) : "";
  const sign = value < 0 ? "-" : "";
  return (
    sign +
    head +
    _int.slice(i).replace(digitsRE, "$1,") +
    _float +
    " " +
    currency
  );
});

// Comma
Vue.filter("comma", function(value) {
  return value ? `${value}, ` : "";
});

// Placeholder
Vue.filter("placeholder", function(value, placeholder) {
  return value || placeholder || "";
});

Vue.filter("ccard", function(ccnumber) {
  if (ccnumber) {
    return (
      ccnumber.slice(0, 4) +
      " - " +
      ccnumber.slice(4, 8) +
      " - " +
      ccnumber.slice(8, 12) +
      " - " +
      ccnumber.slice(12, 16)
    );
  }
  return "";
});
Vue.filter("ccexp", function(ccnumber) {
  if (ccnumber) {
    return ccnumber.slice(0, 2) + "/20" + ccnumber.slice(2, 5);
  }
  return "";
});
Vue.filter("cctype", function(number) {
  if (number) {
    var re = {
      electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+(\**)\d+$/,
      maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+(\**)\d+$/,
      dankort: /^(5019)\d+(\**)\d+$/,
      interpayment: /^(636)\d+(\**)\d+$/,
      unionpay: /^(62|88)\d+(\**)\d+$/,
      visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
      mastercard: /^5[1-5][0-9]{14}$/,
      amex: /^3[47][0-9]{13}$/,
      diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
      discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
      jcb: /^(?:2131|1800|35\d{3})\d{11}$/
    };
    var substr = number.replace(/\*/g, "1");
    for (var key in re) {
      if (re[key].test(substr)) {
        return key;
      }
    }
    return "visa";
  }
  return "";
});
