<template>
  <span v-if="count == 0">
    Non ci sono prodotti nel carrello
  </span>
  <span v-else-if="count == 1"> Hai <b>1 prodotto</b> nel carrello </span>
  <span v-else>
    Hai <b>{{ count }} prodotti</b> nel carrello
  </span>
</template>
<script>
export default {
  name: "CartItemCount",
  props: {
    count: { required: false, type: Number }
  }
};
</script>
