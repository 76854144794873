var render = function (_h,_vm) {var _c=_vm._c;return _c('div',{staticClass:"price"},[(_vm.props.product.priceStandardDisplay || _vm.props.product.priceDisplay)?[(
        _vm.props.product.warehousePromo &&
          _vm.props.product.warehousePromo.warehousePromoTypeId == 1
      )?_c('div',{staticClass:"price-block mxn"},[(_vm.props.product.priceStandardDisplay)?_c('div',{staticClass:"promoMxN-price"},[_vm._v(" "+_vm._s(_vm.parent.$n(_vm.props.product.priceStandardDisplay, "currency"))+" "),_c('span',{staticClass:"other"},[_vm._v("1 pz")])]):_vm._e(),_c('div',{staticClass:"cur-price",class:{ 'promo-price': _vm.props.product.priceStandardDisplay },attrs:{"itemprop":"price"}},[_vm._v(" "+_vm._s(_vm.parent.$n( _vm.props.product.warehousePromo.view.promoOther.nPrice, "currency" ))+" "),_c('span',{staticClass:"other"},[_vm._v(" "+_vm._s(_vm.props.product.warehousePromo.view.promoOther.m)+" pz ")])])]):_c('div',{staticClass:"price_block"},[(
          _vm.props.product.priceStandardDisplay &&
            _vm.props.product.warehousePromo &&
            _vm.props.product.warehousePromo.view.cssClass.indexOf(
              'promo_risparmio'
            ) == -1
        )?_c('div',{staticClass:"old-price"},[_vm._v(" "+_vm._s(_vm.parent.$n(_vm.props.product.priceStandardDisplay, "currency"))+" ")]):_vm._e(),_c('div',{staticClass:"cur-price",class:{
          'promo-price':
            _vm.props.product.priceStandardDisplay ||
            (_vm.props.product.warehousePromo &&
              _vm.props.product.warehousePromo.view.cssClass.indexOf(
                'promo_risparmio'
              ) > -1)
        },attrs:{"itemprop":"price"}},[_vm._v(" "+_vm._s(_vm.parent.$n(_vm.props.product.priceDisplay, "currency"))+" "),(_vm.props.product.productInfos.TIPOLOGIA != 'Pezzo')?_c('span',{staticClass:"weight-unit"},[_vm._v("/"+_vm._s(_vm.props.product.weightUnitDisplay))]):_vm._e()])])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }