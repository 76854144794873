import Vue from "vue";
import Vuex from "vuex";

import app from "~/store/app";
import cart from "~/store/cart";
import category from "~/store/category";
import wordsSearched from "~/store/wordsSearched";

import VuexPersistence from "vuex-persist";
import createLogger from "vuex/dist/logger";

console.log(
  "debug",
  process.env.NODE_ENV,
  process.env.NODE_ENV !== "production"
);

// const vuexLocal = new VuexPersistence({
//   storage: window.localStorage,
//   modules: ["app", "category", "wordsSearched"],
//   reducer: state => ({
//     category: {
//       category: state.category.category,
//       filters: state.category.filters
//     },
//     app: state.app,
//     wordsSearched: state.wordsSearched
//   })
// });
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: state => ({
    category: {
      category: state.category.category,
      filters: state.category.filters
    },
    app: {
      drawerLeft: state.app.drawerLeft,
      drawerRight: state.app.drawerRight,
      lastStartTime: state.app.lastStartTime,
      lastTutorialSeen: state.app.lastTutorialSeen,
      cookiePreferences: state.app.cookiePreferences,
      ordersSentToAnalytics: state.app.ordersSentToAnalytics,
      reopenCookies: state.app.reopenCookies,
      urlScheme: state.app.urlScheme
    },
    wordsSearched: state.wordsSearched,
    filters: state.filters,
    words: state.words,
    fastSearchList: state.fastSearchList
  })
});

const modules = {
  modules: {
    app,
    cart,
    category,
    wordsSearched
  },
  strict: process.env.NODE_ENV !== "production",
  plugins:
    process.env.NODE_ENV !== "production"
      ? [createLogger(), vuexLocal.plugin]
      : [vuexLocal.plugin]
};

Vue.use(Vuex);
const store = new Vuex.Store(modules);
export default store;
