<template>
  <div class="tutorial">
    <v-dialog
      v-model="showCarousel"
      :fullscreen="$vuetify.breakpoint.mobile"
      transition="dialog-bottom-transition"
      class="tutorial-dialog"
    >
      <div class="vuedl-layout__closeBtn" @click="closeTutorial">
        ×
      </div>
      <ProposalTutorialSlider :proposals="carousel" />
    </v-dialog>
  </div>
</template>
<style scoped>
.vuedl-layout__closeBtn {
  color: white;
  opacity: 1;
}
.tutorial-dialog {
  background-color: black;
}
</style>
<script>
import ProposalTutorialSlider from "@/components/proposal/ProposalTutorialSlider";
import CategoryService from "~/service/categoryService";

import { mapActions, mapState } from "vuex";
import get from "lodash/get";
export default {
  name: "Tutorial",
  data() {
    return {
      carousel: [],
      showCarousel: false,
      showTutorial: false
    };
  },
  components: { ProposalTutorialSlider },

  computed: {
    ...mapState({
      lastTutorialSeen: "app/lastTutorialSeen"
    })
  },
  methods: {
    ...mapActions({
      updateLastTutorialSeen: "app/updateLastTutorialSeen"
    }),
    closeTutorial() {
      this.showCarousel = false;
      this.updateLastTutorialSeen(this.tutorialId);
    }
  },
  async mounted() {
    let vm = this;
    try {
      let category = await CategoryService.getCategoryBySlug("tutorial");
      vm.carousel = get(category, "proposals.carousel", []);
      if (vm.carousel.length > 0) {
        vm.tutorialId = vm.carousel[0].id;
        vm.showCarousel = vm.tutorialId != vm.lastTutorialSeen;
        // vm.showCarousel = vm.tutorialId == vm.lastTutorialSeen;
      }
    } catch (e) {
      console.log(e);
    }
  }
};
</script>
