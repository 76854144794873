import Vue from "vue";
import dayjs from "dayjs";
import it from "dayjs/locale/it";
import customParseFormat from "dayjs/plugin/customParseFormat";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(customParseFormat);
dayjs.extend(LocalizedFormat);
dayjs.locale(it);

Object.defineProperties(Vue.prototype, {
  $dayjs: {
    get() {
      return dayjs;
    }
  }
});
const InvalidReturn = "";

Vue.filter("dayjs", (value, method, ...params) => {
  let d = dayjs(value);
  if (!d.isValid()) return InvalidReturn;
  if (method) {
    return d[method].apply(d, params);
  }
});
