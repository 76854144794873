<template>
  <div
    v-if="deliveryFeeMsg && cart.shippingAddress.deliveryServiceId === 2"
    class="subtitle-2 delivery-fee"
  >
    {{ deliveryFeeMsg }}
  </div>
</template>
<style scoped lang="scss">
.delivery-fee {
  color: red;
}
</style>
<script>
import deliveryService from "@/commons/service/deliveryService";
import deliveryReactive from "~/mixins/deliveryReactive";

import { mapState } from "vuex";

export default {
  name: "DeliveryFee",
  mixins: [deliveryReactive],
  data() {
    return {
      deliveryFeeRows: null,
      deliveryFeeMsg: null
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    })
  },
  methods: {
    async reload() {
      const deliveryFeeRules = await deliveryService.getDeliveryFee();
      this.deliveryFeeRows = deliveryFeeRules.deliveryFeeRows;
      this.deliveryFeeRows.sort((a, b) => a.range - b.range);
    },
    calcDeliveryFee() {
      // For testing purposes
      // this.deliveryFeeRows = [
      //   {
      //     fee: 7,
      //     range: 99
      //   },
      //   {
      //     fee: 6,
      //     range: 120
      //   },
      //   {
      //     fee: 4,
      //     range: 300
      //   }
      // ];

      let deliveryFeeMsg = null;
      // let fee = null;

      let feeIndex = 0;
      for (let i = 0; i < this.deliveryFeeRows.length; i++) {
        if (
          this.cart.totalPrice > this.deliveryFeeRows[i].range &&
          !(
            this.deliveryFeeRows[i + 1] &&
            this.cart.totalPrice > this.deliveryFeeRows[i + 1].range
          )
        ) {
          feeIndex = i + 1;
          break;
        }
      }

      // Check if totalPrice is greater than or equal to the highest range
      if (!this.deliveryFeeRows[feeIndex + 1]) {
        this.deliveryFeeMsg = null;
      } else if (
        this.cart.totalPrice <
        this.deliveryFeeRows[feeIndex].range - 30
      ) {
        //check if totalPrice is 30 euros before the next range
        this.deliveryFeeMsg = null;
      } else {
        deliveryFeeMsg = this.deliveryFeeMsg = this.$t("checkout.deliveryFee", [
          this.$n(this.deliveryFeeRows[feeIndex].range, "currency"),
          this.$n(this.deliveryFeeRows[feeIndex + 1].fee, "currency")
        ]);
      }

      return deliveryFeeMsg;
    }
  },
  async created() {
    await this.reload();
    this.calcDeliveryFee();
  },
  watch: {
    "cart.totalPrice": function(newTotalPrice, oldTotalPrice) {
      if (newTotalPrice != oldTotalPrice) {
        this.calcDeliveryFee();
      }
    }
  }
};
</script>
